import React from "react";
import { Container } from "components/Container";
import { Tabs } from "antd";
import { TablaPendientes } from "./TablaPendientes";
import { TablaHistoricos } from "./TablaHistóricos";
import { useAtom } from "jotai";
import * as atoms from "./BandejaEvaluacionStorage";
import EvaluacionService from "services/EvaluacionService";
import { useContext } from "react";
import { SecurityContext } from "context/SecurityContextProvider";
import { EstadoAprobacionEvaluacion } from "enum/EstadoAprobacionEvaluacion";
import { TablaPlanAccion } from "./TablaPlanAccion";
import { ModalVisorPdf } from "components/Modal/ModalVisorPdf";

const BandejaEvaluacionWeb = () => {
	const { TabPane } = Tabs;
	const [evaluacionesHistoricos, setEvaluacionesHistoricos] = useAtom(atoms.evaluacionesHistoricosAtom);
	const [evaluacionesPendientes, setEvaluacionesPendientes] = useAtom(atoms.evaluacionesPendientesAtom);
	const { getUsuarioAutenticado } = useContext(SecurityContext);

	const onChangeTabEvaluaciones = async (key) => {
		if (key === "1") {
			const evaluaciones = await EvaluacionService.listarPorJefeInmediato(
				getUsuarioAutenticado().empresaSeleccionada.id,
				getUsuarioAutenticado().numeroDocumentoIdentidad,
				EstadoAprobacionEvaluacion.PENDIENTE
			);
			setEvaluacionesPendientes(evaluaciones);
		} else if (key === "2") {
			const evaluaciones = await EvaluacionService.listarPorJefeInmediato(
				getUsuarioAutenticado().empresaSeleccionada.id,
				getUsuarioAutenticado().numeroDocumentoIdentidad,
				EstadoAprobacionEvaluacion.APROBADO
			);
			setEvaluacionesHistoricos(evaluaciones);
		}
	};
	return (
		<Container titulo={"Evaluación de Desempeño"}>
			<Tabs defaultActiveKey="1" type="card" onChange={onChangeTabEvaluaciones}>
				<TabPane tab="Pendientes" key="1">
					<TablaPendientes />
				</TabPane>
				<TabPane tab="Históricos" key="2">
					<TablaHistoricos />
				</TabPane>
				<TabPane tab="Planes de Acción" key="3">
					<TablaPlanAccion />
				</TabPane>
			</Tabs>
			<ModalVisorPdf />
		</Container>
	);
};
export default BandejaEvaluacionWeb;
