import React from "react";
import { Descriptions } from "antd";
import { useResultadoEvaluacion } from "../../hooks";

const ResultadoEvaluacion = () => {
	const {
		noAptoStyle,
		aptoConObservacionesStyle,
		buenoStyle,
		destacadoStyle,
		excelenteStyle
	} = useResultadoEvaluacion();

	return (
		<div style={{ marginBottom: "20px" }}>
			<div style={{ fontWeight: "bold", fontSize: "14px", marginBottom: "5px" }}>RESULTADO EVALUACIÓN</div>
			<Descriptions bordered size="small" column={1} labelStyle={{ width: "50%" }}>
				<Descriptions.Item style={noAptoStyle} label="De 0 a 50">
					No Apto Seleccionado
				</Descriptions.Item>
				<Descriptions.Item style={aptoConObservacionesStyle} label="De 51 a 75">
					Apto con Observaciones (Plan de acción)
				</Descriptions.Item>
				<Descriptions.Item style={buenoStyle} label="De 76 a 85">
					Bueno (Plan de Acción) (opcional)
				</Descriptions.Item>
				<Descriptions.Item style={destacadoStyle} label="De 86 a 95">
					Destacado
				</Descriptions.Item>
				<Descriptions.Item style={excelenteStyle} label="De 96 a 100">
					Excelente
				</Descriptions.Item>
			</Descriptions>
		</div>
	);
};

export default ResultadoEvaluacion;
