import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { comentariosAtom, paginaComentarioAtom, publicacionesAtom } from "../store/PublicacionStore";
import { PublicacionService } from "services/comunicacion-cultura";
import LoginService from "services/LoginService";
// const useComentario = (publicacionId) => {
//     const [comentarios, setComentarios] = useAtom(comentariosAtom);
//     const [totalComentarios, setTotalComentarios] = useAtom(totalComentariosAtom);
//     const [paginaComentario, setPaginaComentario] = useAtom(paginaComentarioAtom);

//     const loadMoreComentarios = async () => {
//         console.log("loadMoreComentarios::");
//         fetchComentarios(paginaComentario + 1);
//     }

//     const fetchComentarios = async (paginaComentario) => {
//         console.log("fetchComentarios::");
//         const response = await PublicacionService.listarComentariosPaginado({
//             publicacionId : publicacionId,
//             pagina: paginaComentario,
//             cantidad: 3
//         });
//         if (paginaComentario === 0) {
//             setComentarios(response.comentarios);
//         } else {
//             setComentarios([...comentarios, ...response.comentarios]);
//         }
//         setTotalComentarios(response.totalComentarios);
//         setPaginaComentario(paginaComentario);

//         console.log("DDDDD::", response);
        
//     }
    
//     return {
//         paginaComentario,
//         comentarios,
//         totalComentarios,
//         loadMoreComentarios,
//         setPaginaComentario,
//         fetchComentarios
//     };
// };

// export default useComentario;
const useComentario = (publicacionId) => {
    const [publicaciones, setPublicaciones] = useAtom(publicacionesAtom);
    const [comentarios, setComentarios] = useState([]);
    const [totalComentarios, setTotalComentarios] = useState(0);
    const [paginaComentario, setPaginaComentario] = useState(0);
    const [loading, setLoading] = useState(false);
    const [yaComento, setYaComento] = useState(false);

    useEffect(() => {
        verificarSiUsuarioComento();
    }, [comentarios]);

    const verificarSiUsuarioComento = () => {
        const usuarioYaComento = comentarios.some(comentario => comentario.numeroDocumentoIdentidadUsuarioRegistro === LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad);
        setYaComento(usuarioYaComento);
    };

    const fetchComentarios = async (pagina) => {
        if (loading) return; // Evitar llamadas múltiples
        setLoading(true);

        try {
            const response = await PublicacionService.listarComentariosPaginado({
                publicacionId,
                pagina,
                cantidad: 3
            });

            setComentarios((prevComentarios) => 
                pagina === 0 ? response.comentarios : [...prevComentarios, ...response.comentarios]
            );
            setTotalComentarios(response.totalComentarios);
            setPaginaComentario(pagina);
        } catch (error) {
            console.error("Error al cargar comentarios:", error);
        } finally {
            setLoading(false);
        }
    };

    //const loadMoreComentarios = () => fetchComentarios(paginaComentario + 1);
    const loadMoreComentarios = () => {
        console.log("loadMoreComentarios::", paginaComentario);
        fetchComentarios(paginaComentario + 1);
    }

    const agregarComentario = async (contenido) => {
        try {
            const nuevoComentario = await PublicacionService.registrarComentarios({
                publicacionId: publicacionId,
                contenido: contenido,
                nombreCompletoUsuarioRegistro: LoginService.getUsuarioAutenticado().nombreCompleto,
                numeroDocumentoIdentidadUsuarioRegistro: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad
            });
            
            setComentarios((prev) => [nuevoComentario, ...prev]); // ⬅ Agregar el comentario al inicio
            setTotalComentarios((prev) => prev + 1);
            setYaComento(true); // ⬅ Actualizar estado para ocultar el input

            const _publicaciones = [...publicaciones]; //siempre se debe generar un nuevo array
				const indice = publicaciones.findIndex((publicacion) => publicacion.id === publicacionId);
				const publicacionModificar = publicaciones[indice];
				const publicacionModificado = {
					...publicacionModificar,
					comments: publicacionModificar.comments + 1,
				};
				_publicaciones.splice(indice, 1, publicacionModificado);
				setPublicaciones(_publicaciones);

        } catch (error) {
            console.error("Error al agregar comentario:", error);
        }
    };

    return {
        comentarios,
        totalComentarios,
        paginaComentario,
        loadMoreComentarios,
        fetchComentarios,
        agregarComentario,
        yaComento, // <-- Nuevo estado para verificar si el usuario ya comentó
        setComentarios,
        setTotalComentarios,
        setPaginaComentario
    };
};

export default useComentario;