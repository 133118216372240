import React, { useState, useEffect, useContext } from "react";
import { Button, Input } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import {
	componentSelectedAtom,
	datosFormularioAtom,
	signatureModalVisibleAtom,
	signaturesMetadataAtom,
	urlFirmaHolografaAtom,
	urlHuellaDactilarAtom,
	variablesGlobalesAtom,
	urlFotoAtom,
	componentesAtom,
	visibilidadComponentesAtom,
	loadingAtom,
} from "../../../../VisorFormularioStorage";
import { useSetAtom, useAtomValue, useAtom } from "jotai";
import { atomWithObservable } from "jotai/utils";
import { TipoControl } from "enum";
import moment from "moment";
import "moment/locale/es";
import { SecurityContext } from "context/SecurityContextProvider";
import html2canvas from "html2canvas";
import { SignaturePerson } from "components/common/VisorFormulario/constantes";
import produce from "immer";

const ControlPdf = ({ componente }) => {
	moment.locale("es");
	const variables = useAtomValue(variablesGlobalesAtom);
	const datosFormulario = useAtomValue(datosFormularioAtom);
	const urlFirmaHolografa = useAtomValue(urlFirmaHolografaAtom);
	const urlHuellaDactilar = useAtomValue(urlHuellaDactilarAtom);
	const urlFoto = useAtomValue(urlFotoAtom);
	const [visibilidadComponentes, setVisibilidadComponentes] = useAtom(visibilidadComponentesAtom);
	const loading = useAtomValue(loadingAtom);

	const {
		control,
		setValue,
		register,
		watch,
		formState: { errors },
	} = useFormContext();

	const setSignatureModalVisible = useSetAtom(signatureModalVisibleAtom);
	const setComponentSelected = useSetAtom(componentSelectedAtom);

	const [signaturesMetadata, setSignaturesMetadata] = useAtom(signaturesMetadataAtom);

	const [signature, setSignature] = useState(undefined);
	const [imagen, setImagen] = useState(undefined);

	const [visible, setVisible] = useState(componente.condicional ? false : true);

	const { getUsuarioAutenticado } = useContext(SecurityContext);

	const isExplomin = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20501523837" ? true : false;

	const watchControl = useWatch({
		name: componente.type === TipoControl.RADIO_BUTTON ? componente.name : componente.key,
	});

	useEffect(() => {
		const inicializar = () => {
			if (componente.type === TipoControl.RADIO_BUTTON && watchControl !== "") {
				setVisibilidadComponentes({ ...visibilidadComponentes, [componente.name]: watchControl });
			} else if (componente.type === TipoControl.CHECKBOX && watchControl !== "") {
				const valor = watchControl !== "" && watchControl !== undefined ? "checked" : undefined;
				setVisibilidadComponentes({
					...visibilidadComponentes,
					[componente.key]: valor,
				});
			} else if (componente.type === TipoControl.LISTA && watchControl !== "") {
				setVisibilidadComponentes({ ...visibilidadComponentes, [componente.key]: watchControl });
			}
		};

		inicializar();
	}, [watchControl]);


	useEffect(() => {
		const cargarHuellaDactilar = () => {
			if (
				urlHuellaDactilar &&
				componente.type === TipoControl.IMAGEN &&
				componente.defaultValue === "HUELLA_DACTILAR"
			) {
				fetch(urlHuellaDactilar)
					.then((response) => response.blob())
					.then((blob) => {
						const reader = new FileReader();
						reader.readAsDataURL(blob);
						reader.onload = () => {
							setValue(nombreControl, reader?.result);
							setImagen({
								key: componente.key,
								base64: reader.result,
							});
						};
					})
					.catch((error) => {
						console.log("no se pudo cargar la huella dactilar", error);
					});
			}
		};
		cargarHuellaDactilar();
	}, [urlHuellaDactilar]);

	useEffect(() => {
		const cargarFoto = () => {
			if (urlFoto && componente.type === TipoControl.IMAGEN && componente.defaultValue === "FOTO") {
				fetch(urlFoto)
					.then((response) => response.blob())
					.then((blob) => {
						const reader = new FileReader();
						reader.readAsDataURL(blob);
						reader.onload = () => {
							setValue(nombreControl, reader?.result);
							setImagen({
								key: componente.key,
								base64: reader.result,
							});
						};
					})
					.catch((error) => {
						console.log("no se pudo cargar la foto", error);
					});
			}
		};
		cargarFoto();
	}, [urlFoto]);

	useEffect(() => {
		const cargarFirmaHolografa = () => {
			if (
				urlFirmaHolografa &&
				componente.type === TipoControl.SIGNATURE
				//&&componente.signaturePerson === SignaturePerson.COLABORADOR
			) {
				fetch(urlFirmaHolografa)
					.then((response) => response.blob())
					.then((blob) => {
						const reader = new FileReader();
						reader.readAsDataURL(blob);
						reader.onload = () => {
							setSignaturesMetadata(
								produce((draft) => {
									const signature = draft.find((s) => s.key === componente.key);
									if (signature) {
										signature.base64 = reader.result;
									} else {
										draft.push({
											key: componente.key,
											base64: reader.result,
										});
									}
								})
							);
						};
					})
					.catch((error) => {
						console.log("no se pudo cargar la url de firma hológrafa", error);
					});
			}
		};

		cargarFirmaHolografa();
	}, [urlFirmaHolografa]);

	const getNombreControl = () => {
		let nombreControl = componente.key;
		if (componente.groupBy) {
			nombreControl = `${componente.groupBy.table}.${componente.groupBy.rowNumber - 1}.${componente.groupBy.column
				}`;
		} else {
			if (componente.type === TipoControl.RADIO_BUTTON) {
				nombreControl = componente.name;
			} else if (componente.type === TipoControl.SIGNATURE) {
				nombreControl = componente.key + "_signature_base64";
			} else if (componente.type === TipoControl.IMAGEN) {
				nombreControl = componente.key + "_imagen_base64";
			} else {
				nombreControl = componente.key;
			}
		}
		return nombreControl;
	};

	const getClassName = (nombreControl) => {
		let className = "";
		if (errors) {
			if (componente.type === TipoControl.CAJA_TEXTO) {
				if (componente.groupBy) {
					className = "formulario-input";
					const errores = errors[componente.groupBy.table];
					if (
						errores &&
						errores[componente.groupBy.rowNumber - 1] &&
						errores[componente.groupBy.rowNumber - 1][componente.groupBy.column]
					) {
						className = "formulario-input-error";
					}
				} else {
					className = errors[nombreControl] ? "formulario-input-error" : "formulario-input";
				}
			} else if (componente.type === TipoControl.LISTA) {
				if (componente.groupBy) {
					className = "formulario-list";
					const errores = errors[componente.groupBy.table];
					if (
						errores &&
						errores[componente.groupBy.rowNumber - 1] &&
						errores[componente.groupBy.rowNumber - 1][componente.groupBy.column]
					) {
						className = "formulario-list-error";
					}
				} else {
					className = errors[nombreControl] ? "formulario-list-error" : "formulario-list";
				}
			} else if (componente.type === TipoControl.CHECKBOX) {
				if (componente.groupBy) {
					className = "formulario-control-container";
					const errores = errors[componente.groupBy.table];
					if (
						errores &&
						errores[componente.groupBy.rowNumber - 1] &&
						errores[componente.groupBy.rowNumber - 1][componente.groupBy.column]
					) {
						className = "formulario-control-container-error";
					}
				} else {
					className = errors[nombreControl]
						? "formulario-control-container-error"
						: "formulario-control-container";
				}
			} else if (componente.type === TipoControl.RADIO_BUTTON) {
				if (componente.groupBy) {
					className = "formulario-control-container";
					const errores = errors[componente.groupBy.table];
					if (
						errores &&
						errores[componente.groupBy.rowNumber - 1] &&
						errores[componente.groupBy.rowNumber - 1][componente.groupBy.column]
					) {
						className = "formulario-control-container-error";
					}
				} else {
					className = errors[nombreControl]
						? "formulario-control-container-error"
						: "formulario-control-container";
				}
			} else if (componente.type === TipoControl.SIGNATURE) {
				className = errors[nombreControl] ? "formulario-signature-button-error" : "formulario-signature-button";
			} else if (componente.type === TipoControl.IMAGEN) {
				className = errors[nombreControl] ? "formulario-file-error" : "formulario-file";
			}
		}
		return className;
	};

	let nombreControl = getNombreControl();
	let className = getClassName(nombreControl);

	useEffect(() => {
		const loadSignature = async () => {
			if (componente.type === TipoControl.SIGNATURE) {
				const signature = signaturesMetadata.find((s) => s.key === componente.key);
				setSignature(signature);
				setValue(nombreControl, signature?.base64);
			}
		};

		loadSignature();
	}, [signaturesMetadata]);

	useEffect(() => {
		const loadSignature = async () => {
			if (componente.type === TipoControl.SIGNATURE) {
				if (componente.signatureType === "IMAGEN_Y_DESCRIPCION") {
					const divElement = document.getElementById("signature" + nombreControl);

					const scale = 2;
					const canvas = await html2canvas(divElement, { scale });

					const base64Image = canvas.toDataURL("image/png");

					if (base64Image !== "data:,") {
						setValue(nombreControl, base64Image);
					}
				}
			}
		};

		loadSignature();
	}, [signature]);

	const getMensajeRequerido = () => {
		if (componente.required) {
			if (componente.groupBy) {
				return "El campo " + componente.label + " de la tabla " + componente.groupBy.table + " es requerido.";
			}
			return "El campo " + componente.label + " es requerido.";
		}
		return false;
	};

	const reglas = {
		required: getMensajeRequerido(),
	};

	var valorInicial = "";
	var fontSize = componente.size && componente.size > 0 ? componente.size + "px" : "12px";

	if (componente.type === TipoControl.CAJA_TEXTO) {
		valorInicial = "";
		if (componente.defaultValue) {
			if (componente.defaultValue === "${PERSONALIZADO}") {
				valorInicial = reemplazarVariables(componente.customDefaultValue, variables);
			} else if (componente.formatDefaultValue) {
				valorInicial = moment(variables[componente.defaultValue], "DD/MM/YYYY").format(
					componente.formatDefaultValue
				);
			} else {
				valorInicial = variables[componente.defaultValue];
			}
		} else if (datosFormulario) {
			valorInicial = datosFormulario[componente.key];
		}

		if (
			componente.condicional?.control !== null &&
			componente.condicional?.control !== undefined &&
			(visibilidadComponentes[componente.condicional?.control] === undefined
				|| !componente.condicional.valor.toLowerCase().split("\n").some(valor => valor === visibilidadComponentes[componente.condicional?.control].toLowerCase()))
			// || !componente.condicional.valor.toLowerCase().includes(visibilidadComponentes[componente.condicional?.control].toLowerCase()))
			// || visibilidadComponentes[componente.condicional?.control].toLowerCase() !== componente.condicional.valor.toLowerCase())
		) {
			return "";
		}

		return (
			<Controller
				name={nombreControl}
				control={control}
				rules={reglas}
				defaultValue={valorInicial}
				render={({ field }) => (
					<Input
						className={className}
						style={{
							top: componente.overlay.top,
							left: componente.overlay.left,
							height: componente.overlay.height,
							width: componente.overlay.width,
							fontSize: fontSize,
						}}
						readOnly={componente.readOnly}
						maxLength={componente.maxLength && componente.maxLength > 0 ? componente.maxLength : undefined}
						autoComplete="off"
						{...field}
					/>
				)}
			/>
		);
	} else if (componente.type === TipoControl.LISTA) {
		valorInicial = "";
		if (datosFormulario) {
			valorInicial = datosFormulario[componente.key];
		}

		if (
			componente.condicional?.control !== null &&
			componente.condicional?.control !== undefined &&
			(visibilidadComponentes[componente.condicional?.control] === undefined
				|| !componente.condicional.valor.toLowerCase().split("\n").some(valor => valor === visibilidadComponentes[componente.condicional?.control].toLowerCase()))
			// || !componente.condicional.valor.toLowerCase().includes(visibilidadComponentes[componente.condicional?.control].toLowerCase()))
			// || visibilidadComponentes[componente.condicional?.control].toLowerCase() !== componente.condicional.valor.toLowerCase())
		) {
			return "";
		}

		return (
			<Controller
				name={nombreControl}
				control={control}
				rules={reglas}
				defaultValue={valorInicial}
				render={({ field }) => (
					<select
						name="select"
						className={className}
						style={{
							top: componente.overlay.top,
							left: componente.overlay.left,
							height: componente.overlay.height,
							width: componente.overlay.width,
							fontSize: fontSize
							// fontSize: componente.overlay.height <= 12 ? "9px" : "12px",
						}}
						{...field}
					>
						<option value=""></option>
						{componente.items.map((item) => {
							return (
								<option key={item.key} value={item.key}>
									{item.value}
								</option>
							);
						})}
					</select>
				)}
			/>
		);
	} else if (componente.type === TipoControl.CHECKBOX) {
		console.log("CHECKBOX");
		valorInicial = false;
		if (componente.defaultValue) {
			valorInicial = componente.defaultValue === "checked" ? true : false;
		} else if (datosFormulario) {
			valorInicial = datosFormulario[componente.key] ? true : false;
		}

		return (
			<Controller
				key={componente.key}
				name={nombreControl}
				control={control}
				rules={reglas}
				defaultValue={valorInicial}
				render={({ field }) => (
					<div
						className={className}
						style={{
							top: componente.overlay.top,
							left: componente.overlay.left,
							height: componente.overlay.height,
							width: componente.overlay.width,
						}}
					>
						<input
							className="formulario-checkbox"
							{...field}
							type="checkbox"
							onChange={(e) => field.onChange(e.target.checked ? componente.value : false)}
							// defaultChecked={field.value}
							checked={field.value}
						/>
					</div>
				)}
			/>
		);
	} else if (componente.type === TipoControl.RADIO_BUTTON) {
		//valorInicial = false;
		valorInicial = "";
		if (componente.defaultValue) {
			valorInicial = variables[componente.defaultValue]?.toUpperCase();
		} else if (datosFormulario) {
			valorInicial = datosFormulario[componente.name]?.toUpperCase();
		}

		if (
			componente.condicional?.control !== null &&
			componente.condicional?.control !== undefined &&
			(visibilidadComponentes[componente.condicional?.control] === undefined ||
				visibilidadComponentes[componente.condicional?.control].toLowerCase() !==
				componente.condicional.valor.toLowerCase())
		) {
			return "";
		}

		return (
			<Controller
				key={componente.key}
				name={nombreControl}
				control={control}
				rules={reglas}
				defaultValue={valorInicial}
				render={({ field }) => {
					return (
						<div
							className={className}
							style={{
								top: componente.overlay.top,
								left: componente.overlay.left,
								height: componente.overlay.height,
								width: componente.overlay.width,
							}}
						>
							<input
								className="formulario-radio-button"
								{...field}
								type="radio"
								value={componente.value.toUpperCase()}
								defaultChecked={valorInicial === componente?.value.toUpperCase()}
							/>
						</div>
					);
				}}
			/>
		);
	} else if (componente.type === TipoControl.SIGNATURE) {
		const onClickBtnFirmar = () => {
			setComponentSelected(componente);
			if (isExplomin) {
				setSignatureModalVisible(false);
			} else {
				setSignatureModalVisible(true);
			}
		};
		return (
			<React.Fragment>
				<input {...register(nombreControl, reglas)} type="hidden" className="formulario-hidden" />
				<div
					id={"signature" + nombreControl}
					className="formulario-signature-container"
					style={{
						top: componente.overlay.top,
						left: componente.overlay.left,
						height: componente.overlay.height,
						width: componente.overlay.width,
						display: signature ? "flex" : "none",
						maxWidth: componente.overlay.width,
						maxHeight: componente.overlay.height,
					}}
					onClick={onClickBtnFirmar}
				>
					<img
						style={{
							flex: "1",
							width: componente.signaturePerson === SignaturePerson.COLABORADOR ? "100%" : "50%",
							width: "50%",
						}}
						src={signature?.base64}
					/>
					{componente.signaturePerson !== SignaturePerson.COLABORADOR && (
						<div
							style={{
								flex: "1",
								fontSize: "0.5em",
								display: "flex",
								justifyContent: "center",
								flexDirection: "column",
							}}
						>
							Firmado por: {getUsuarioAutenticado().nombreCompleto}
							<br />
							Fecha: {moment().format("DD/MM/YYYY")}
						</div>
					)}
				</div>
				<Button
					className={className}
					onClick={onClickBtnFirmar}
					style={{
						top: componente.overlay.top,
						left: componente.overlay.left,
						height: componente.overlay.height,
						width: componente.overlay.width,
						display: signature?.base64 ? "none" : "inherit",
					}}
				>
					<span style={{ fontWeight: "bold", fontSize: "12px" }}>Firmar aquí</span>
				</Button>
			</React.Fragment>
		);
	} else if (componente.type === TipoControl.IMAGEN) {
		const onChangeImagen = (e) => {
			if (e.target.files[0] !== undefined) {
				let reader = new FileReader();
				reader.readAsDataURL(e.target.files[0]);
				reader.onload = () => {
					setValue(nombreControl, reader?.result);
					setImagen({
						key: componente.key,
						base64: reader.result,
					});
				};
			}
		};
		return (
			<React.Fragment>
				<input {...register(nombreControl, reglas)} type="hidden" className="formulario-hidden" />
				<label
					className={className}
					style={{
						top: componente.overlay.top,
						left: componente.overlay.left,
						height: componente.overlay.height,
						width: componente.overlay.width,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						cursor: "pointer",
					}}
				>
					{imagen?.base64 && (
						<img
							style={{
								top: componente.overlay.top,
								left: componente.overlay.left,
								height: componente.overlay.height,
								width: componente.overlay.width,
								display: imagen ? "flex" : "none",
							}}
							src={imagen?.base64}
						/>
					)}
					{!imagen?.base64 && (
						<React.Fragment>
							<UploadOutlined style={{ fontSize: "16px" }} />
							<span style={{ fontSize: "10px" }}>Subir imagen</span>
						</React.Fragment>
					)}
					{!isExplomin && (
						<input style={{ display: "none" }} type="file" onChange={onChangeImagen} />
					)}
				</label>
			</React.Fragment>
		);
	} else if (componente.type === TipoControl.ETIQUETA) {
		valorInicial = "";
		if (componente.defaultValue) {
			if (componente.defaultValue === "${PERSONALIZADO}") {
				valorInicial = reemplazarVariables(componente.customDefaultValue, variables);
			} else if (componente.formatDefaultValue) {
				valorInicial = moment(variables[componente.defaultValue], "DD/MM/YYYY").format(
					componente.formatDefaultValue
				);
			} else {
				valorInicial = variables[componente.defaultValue];
			}
		} else if (datosFormulario) {
			valorInicial = datosFormulario[componente.key];
		}

		return (
			<Controller
				name={nombreControl}
				control={control}
				rules={reglas}
				defaultValue={valorInicial}
				render={({ field }) => (
					<Input.TextArea
						className="formulario-label"
						style={{
							top: componente.overlay.top,
							left: componente.overlay.left,
							height: componente.overlay.height,
							width: componente.overlay.width,
							fontSize: fontSize,
							resize: 'none',
						}}
						readOnly={true}
						bordered={false}
						{...field}
					/>
				)}
			/>
		);
	}
	return "";
};

function reemplazarVariables(texto, variables) {
	return texto.replace(/(\$\{[^}]+\})/g, (match, variable) => {
		if (variables.hasOwnProperty(variable)) {
			return variables[variable];
		}
		return match;
	});
}

export default ControlPdf;
