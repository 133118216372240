import React, { useEffect } from "react";
import { useAtom } from "jotai";
import { publicacionesAtom, paginaAtom, totalPublicacionesAtom } from "../store/PublicacionStore";
import { PublicacionService } from "services/comunicacion-cultura";
import LoginService from "services/LoginService";

const usePublicacion = () => {
    const [publicaciones, setPublicaciones] = useAtom(publicacionesAtom);
    const [totalPublicaciones, setTotalPublicaciones] = useAtom(totalPublicacionesAtom);
    const [pagina, setPagina] = useAtom(paginaAtom);


    useEffect(() => {
		const cargarinformacion = async () => {
            fetchPublicaciones(0);
		};

		cargarinformacion();
    }, []);
    
    const loadMorePublicaciones = async () => {
        console.log("loadMorePublicaciones::");
        fetchPublicaciones(pagina + 1);
    }

    const fetchPublicaciones = async (pagina) => {
        const response = await PublicacionService.listarPaginado({
            pagina: pagina,
            cantidad: 10
        });
        if (pagina === 0) {
            setPublicaciones(response.publicaciones);
        } else {
            setPublicaciones([...publicaciones, ...response.publicaciones]);
        }
        setTotalPublicaciones(response.totalPublicaciones);
        setPagina(pagina);

        console.log("DDDDD::", response);
        
    }

    const handleLikeClick = async (id) => {
		try {
			console.log("likes id", id);
			let datos = {
				publicidadId: id,
				numeroDocumentoIdentidad: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad,
				nombreCompleto: LoginService.getUsuarioAutenticado().nombreCompleto,
			};
			const respuesta = await PublicacionService.actualizarContadorLikes(datos);
			console.log("handleLikeClick", respuesta);
			if (respuesta) {
				const _publicaciones = [...publicaciones]; //siempre se debe generar un nuevo array
				const indice = publicaciones.findIndex((publicacion) => publicacion.id === id);
				const publicacionModificar = publicaciones[indice];
				const publicacionModificado = {
					...publicacionModificar,
					likes: publicacionModificar.likes + 1,
					hearts: publicacionModificar.hearts > 0 ? publicacionModificar.hearts - 1 : 0,
					likedColaborador: true,
					heartedColaborador: false,
				};
				_publicaciones.splice(indice, 1, publicacionModificado);
				setPublicaciones(_publicaciones);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleHeartClick = async (id) => {
		try {
			console.log("hearts id", id);
			let datos = {
				publicidadId: id,
				numeroDocumentoIdentidad: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad,
				nombreCompleto: LoginService.getUsuarioAutenticado().nombreCompleto,
			};
			const respuesta = await PublicacionService.actualizarContadorHearts(datos);
			console.log("handleHeartClick", respuesta);
			if (respuesta) {
				const _publicaciones = [...publicaciones]; //siempre se debe generar un nuevo array
				const indice = publicaciones.findIndex((publicacion) => publicacion.id === id);
				const publicacionModificar = publicaciones[indice];
				const publicacionModificado = {
					...publicacionModificar,
					hearts: publicacionModificar.hearts + 1,
					likes: publicacionModificar.likes > 0 ? publicacionModificar.likes - 1 : 0,
					heartedColaborador: true,
					likedColaborador: false,
				};
				_publicaciones.splice(indice, 1, publicacionModificado);
				setPublicaciones(_publicaciones);
			}
		} catch (error) {
			console.error(error);
		}
	};
	
	// const handleCommentAddClick = async (id, newComment) => {
	// 	try {
	// 		console.log("handleCommentClick id", id);
	// 		console.log("newComment", newComment);
	// 		let datos = {
	// 			publicacionId: id,
	// 			contenido: newComment,
	// 			nombreCompletoUsuarioRegistro: LoginService.getUsuarioAutenticado().nombreCompleto,
	// 			numeroDocumentoIdentidadUsuarioRegistro: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad
	// 		}
	// 		const respuesta = await PublicacionService.registrarComentarios(datos);
	// 		if (respuesta) {
	// 			const _publicaciones = [...publicaciones]; //siempre se debe generar un nuevo array
	// 			const indice = publicaciones.findIndex((publicacion) => publicacion.id === id);
	// 			const publicacionModificar = publicaciones[indice];
	// 			let comentarios = publicacionModificar.comentarios;
	// 			comentarios.add(datos);
	// 			const publicacionModificado = {
	// 				...publicacionModificar,
	// 				comments: publicacionModificar.comments + 1,
	// 				comentarios: comentarios,
	// 				comento:true
	// 			};
	// 			_publicaciones.splice(indice, 1, publicacionModificado);
	// 			setPublicaciones(_publicaciones);
	// 		}
	// 	} catch (error) {
	// 		console.error(error);
	// 	}
	// };

// 	const handleCommentAddClick = async (id, newComment) => {
// 	try {
// 		console.log("handleCommentClick id", id);
// 		console.log("newComment", newComment);

// 		let datos = {
// 			publicacionId: id,
// 			contenido: newComment,
// 			nombreCompletoUsuarioRegistro: LoginService.getUsuarioAutenticado().nombreCompleto,
// 			numeroDocumentoIdentidadUsuarioRegistro: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad
// 		};

// 		const respuesta = await PublicacionService.registrarComentarios(datos);
// 		if (respuesta) {
// 			setPublicaciones((prevPublicaciones) => {
// 				return prevPublicaciones.map((publicacion) => {
// 					if (publicacion.id === id) {
// 						return {
// 							...publicacion,
// 							comments: (publicacion.comments || 0) + 1, // Asegura que comments tenga un valor
// 							comentarios: [...(publicacion.comentarios || []), datos], // Inicializa comentarios si es null
// 							comento: true
// 						};
// 					}
// 					return publicacion;
// 				});
// 			});
// 		}
// 	} catch (error) {
// 		console.error(error);
// 	}
// };

	
	// const handleCommentLoadClick = async (id) => {
	// 	try {
	// 		console.log("handleCommentLoadClick id", id);
			
	// 		const respuesta = await PublicacionService.listarComentarios(id);

			

	// 		if (respuesta && respuesta.length > 0) {
	// 			let comento = respuesta.some(
	// 			(comentario) => comentario.numeroDocumentoIdentidadUsuarioRegistro === LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad
	// 			);

	// 			const _publicaciones = [...publicaciones]; //siempre se debe generar un nuevo array
	// 			const indice = publicaciones.findIndex((publicacion) => publicacion.id === id);
	// 			const publicacionModificar = publicaciones[indice];
	// 			const publicacionModificado = {
	// 				...publicacionModificar,
	// 				comentarios: respuesta,
	// 				comento: comento
	// 			};
	// 			_publicaciones.splice(indice, 1, publicacionModificado);
	// 			setPublicaciones(_publicaciones);
	// 		}
	// 	} catch (error) {
	// 		console.error(error);
	// 	}
    // };
    
    return {
        pagina,
        publicaciones,
        totalPublicaciones,
        loadMorePublicaciones,
        setPagina,
        handleHeartClick,
		handleLikeClick,
		// handleCommentAddClick,
		// handleCommentLoadClick
    };
};

export default usePublicacion;
