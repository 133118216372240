import { Alert, Descriptions, Form, Input, Modal } from 'antd';
import React, { useEffect } from 'react';
import { useModalRegistrarAsistencia } from '../hooks';

const ModalRegistrarAsistencia = () => {

    const {
        open,
        asistencia,
        asistenciaSeleccionada,
        setFormInstance,
        firmarAsistencia,
        fetchAsistencia,
        cerrarModal
    } = useModalRegistrarAsistencia();

    const [form] = Form.useForm();

    useEffect(() => {
        if (open && asistenciaSeleccionada) {
            setFormInstance(form);
            fetchAsistencia();
        }
    }, [open, asistenciaSeleccionada])

    return (
        <Modal
            title="Detalle Asistencia"
            open={open}
            onOk={firmarAsistencia}
            onCancel={cerrarModal}
            okText="Firmar Asistencia"
            maskClosable={false}
            centered
        >
            <Descriptions
                bordered
                size="small"
                column={1}
                style={{ marginBottom: "15px" }}
            >
                <Descriptions.Item label="Tipo">{asistencia?.tipo}</Descriptions.Item>
                <Descriptions.Item label="Tema">{asistencia?.tema}</Descriptions.Item>
                <Descriptions.Item label="Objetivo">{asistencia?.objetivo}</Descriptions.Item>
                <Descriptions.Item label="Lugar">{asistencia?.lugar}</Descriptions.Item>
                <Descriptions.Item label="Fecha">{asistencia?.fecha}</Descriptions.Item>
                <Descriptions.Item label="Horario">{asistencia?.horario}</Descriptions.Item>
            </Descriptions>

            {asistencia?.expositor &&
                <>
                    <Alert
                        message="Completar la siguiente información de ser necesaria."
                        type="info"
                        showIcon
                        style={{ paddingBottom: "10px" }}
                    />
                    <Form form={form} layout='vertical' autoComplete="off">
                        <Form.Item
                            label="Observaciones"
                            name="observaciones"
                        >
                            <Input.TextArea />
                        </Form.Item>

                        <Form.Item
                            label="Acuerdos y/o compromisos"
                            name="acuerdos"
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Form>
                </>
            }
        </Modal>
    )
};

export default ModalRegistrarAsistencia;