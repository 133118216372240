import { BaseService } from "services/BaseService";

const AsistenciaService = {

    async obtener(detalleAsistenciaId) {
        const respuesta = await BaseService.get(`/portal-colaborador/v3/asistencia/${detalleAsistenciaId}`);
        return respuesta.data;
    },

    async firmar(detalleAsistenciaId, payload) {
        const respuesta = await BaseService.post(`/portal-colaborador/v3/asistencia/${detalleAsistenciaId}/firmar`, payload);
        return respuesta.data;
    },

};
export default AsistenciaService;
