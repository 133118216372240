import React, { useContext, useState } from "react";
import {
	Button,
	Col,
	DatePicker,
	Divider,
	Form,
	Input,
	Row,
	Select,
	Upload,
	message,
	Space,
	Radio,
	Popconfirm,
} from "antd";
import { TipoDocumentoIdentidad } from "enum/TipoDocumentoIdentidad";
import {
	RightOutlined,
	UploadOutlined,
	HomeOutlined,
	IdcardOutlined,
	RiseOutlined,
	PhoneOutlined,
	EditOutlined,
	WalletOutlined,
} from "@ant-design/icons";
import { useCargarInformacionPersonal } from "../../hooks";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { OnboardingService } from "services";
import { useHistory } from "react-router-dom";
import {
	pasoActualAtom,
	departamentoAtom,
	mostrarCamposPorPaisAtom,
	provinciaAtom,
	distritoAtom,
	departamentoNacimientoAtom,
	provinciaNacimientoAtom,
	distritoNacimientoAtom,
} from "pages/onboarding/OnboardingStore";
import { useEffect } from "react";
import { SecurityContext } from "context/SecurityContextProvider";
import UbigeoService from "../../../../../../services/UbigeoService";
import ImgCrop from "antd-img-crop";
import { ModalSubirFirma } from "components/common";

const firmaHolografaAtom = atom(undefined);
const huellaDactilarAtom = atom(undefined);
const fotoPersonalAtom = atom(undefined);

const lstPaises = [
	{ value: "AUSTRIA", label: "AUSTRIA" },
	{ value: "BELGICA", label: "BELGICA" },
	{ value: "BULGARIA", label: "BULGARIA" },
	{ value: "CHIPRE", label: "CHIPRE" },
	{ value: "DINAMARCA", label: "DINAMARCA" },
	{ value: "ESPAÑA", label: "ESPAÑA" },
	{ value: "FINLANDIA", label: "FINLANDIA" },
	{ value: "FRANCIA", label: "FRANCIA" },
	{ value: "GRECIA", label: "GRECIA" },
	{ value: "HUNGRIA", label: "HUNGRIA" },
	{ value: "IRLANDA", label: "IRLANDA" },
	{ value: "ITALIA", label: "ITALIA" },
	{ value: "LUXEMBURGO", label: "LUXEMBURGO" },
	{ value: "MALTA", label: "MALTA" },
	{ value: "PAISES BAJOS", label: "PAISES BAJOS" },
	{ value: "POLONIA", label: "POLONIA" },
	{ value: "PORTUGAL", label: "PORTUGAL" },
	{ value: "REINO UNIDO", label: "REINO UNIDO" },
	{ value: "ALEMANIA", label: "ALEMANIA" },
	{ value: "RUMANIA", label: "RUMANIA" },
	{ value: "SUECIA", label: "SUECIA" },
	{ value: "LETONIA", label: "LETONIA" },
	{ value: "ESTONIA", label: "ESTONIA" },
	{ value: "LITUANIA", label: "LITUANIA" },
	{ value: "REPUBLICA CHECA", label: "REPUBLICA CHECA" },
	{ value: "REPUBLICA ESLOVACA", label: "REPUBLICA ESLOVACA" },
	{ value: "ESLOVENIA", label: "ESLOVENIA" },
	{
		value: "OTROS PAISES O TERRITORIOS DE LA UNION EUROPEA",
		label: "OTROS PAISES O TERRITORIOS DE LA UNION EUROPEA",
	},
	{ value: "ALBANIA", label: "ALBANIA" },
	{ value: "ISLANDIA", label: "ISLANDIA" },
	{ value: "LIECHTENSTEIN", label: "LIECHTENSTEIN" },
	{ value: "MONACO", label: "MONACO" },
	{ value: "NORUEGA", label: "NORUEGA" },
	{ value: "ANDORRA", label: "ANDORRA" },
	{ value: "SAN MARINO", label: "SAN MARINO" },
	{ value: "SANTA SEDE", label: "SANTA SEDE" },
	{ value: "SUIZA", label: "SUIZA" },
	{ value: "UCRANIA", label: "UCRANIA" },
	{ value: "MOLDAVIA", label: "MOLDAVIA" },
	{ value: "BELARUS", label: "BELARUS" },
	{ value: "GEORGIA", label: "GEORGIA" },
	{ value: "BOSNIA Y HERZEGOVINA", label: "BOSNIA Y HERZEGOVINA" },
	{ value: "CROACIA", label: "CROACIA" },
	{ value: "ARMENIA", label: "ARMENIA" },
	{ value: "RUSIA", label: "RUSIA" },
	{ value: "MACEDONIA ", label: "MACEDONIA " },
	{ value: "SERBIA", label: "SERBIA" },
	{ value: "MONTENEGRO", label: "MONTENEGRO" },
	{ value: "GUERNESEY", label: "GUERNESEY" },
	{ value: "SVALBARD Y JAN MAYEN", label: "SVALBARD Y JAN MAYEN" },
	{ value: "ISLAS FEROE", label: "ISLAS FEROE" },
	{ value: "ISLA DE MAN", label: "ISLA DE MAN" },
	{ value: "GIBRALTAR", label: "GIBRALTAR" },
	{ value: "ISLAS DEL CANAL", label: "ISLAS DEL CANAL" },
	{ value: "JERSEY", label: "JERSEY" },
	{ value: "ISLAS ALAND", label: "ISLAS ALAND" },
	{ value: "TURQUIA", label: "TURQUIA" },
	{
		value: "OTROS PAISES O TERRITORIOS DEL RESTO DE EUROPA",
		label: "OTROS PAISES O TERRITORIOS DEL RESTO DE EUROPA",
	},
	{ value: "BURKINA FASO", label: "BURKINA FASO" },
	{ value: "ANGOLA", label: "ANGOLA" },
	{ value: "ARGELIA", label: "ARGELIA" },
	{ value: "BENIN", label: "BENIN" },
	{ value: "BOTSWANA", label: "BOTSWANA" },
	{ value: "BURUNDI", label: "BURUNDI" },
	{ value: "CABO VERDE", label: "CABO VERDE" },
	{ value: "CAMERUN", label: "CAMERUN" },
	{ value: "COMORES", label: "COMORES" },
	{ value: "CONGO", label: "CONGO" },
	{ value: "COSTA DE MARFIL", label: "COSTA DE MARFIL" },
	{ value: "DJIBOUTI", label: "DJIBOUTI" },
	{ value: "EGIPTO", label: "EGIPTO" },
	{ value: "ETIOPIA", label: "ETIOPIA" },
	{ value: "GABON", label: "GABON" },
	{ value: "GAMBIA", label: "GAMBIA" },
	{ value: "GHANA", label: "GHANA" },
	{ value: "GUINEA", label: "GUINEA" },
	{ value: "GUINEA-BISSAU", label: "GUINEA-BISSAU" },
	{ value: "GUINEA ECUATORIAL", label: "GUINEA ECUATORIAL" },
	{ value: "KENIA", label: "KENIA" },
	{ value: "LESOTHO", label: "LESOTHO" },
	{ value: "LIBERIA", label: "LIBERIA" },
	{ value: "LIBIA", label: "LIBIA" },
	{ value: "MADAGASCAR", label: "MADAGASCAR" },
	{ value: "MALAWI", label: "MALAWI" },
	{ value: "MALI", label: "MALI" },
	{ value: "MARRUECOS", label: "MARRUECOS" },
	{ value: "MAURICIO", label: "MAURICIO" },
	{ value: "MAURITANIA", label: "MAURITANIA" },
	{ value: "MOZAMBIQUE", label: "MOZAMBIQUE" },
	{ value: "NAMIBIA", label: "NAMIBIA" },
	{ value: "NIGER", label: "NIGER" },
	{ value: "NIGERIA", label: "NIGERIA" },
	{ value: "REPUBLICA CENTROAFRICANA", label: "REPUBLICA CENTROAFRICANA" },
	{ value: "SUDAFRICA", label: "SUDAFRICA" },
	{ value: "RUANDA", label: "RUANDA" },
	{ value: "SANTO TOME Y PRINCIPE", label: "SANTO TOME Y PRINCIPE" },
	{ value: "SENEGAL", label: "SENEGAL" },
	{ value: "SEYCHELLES", label: "SEYCHELLES" },
	{ value: "SIERRA LEONA", label: "SIERRA LEONA" },
	{ value: "SOMALIA", label: "SOMALIA" },
	{ value: "SUDAN", label: "SUDAN" },
	{ value: "SWAZILANDIA", label: "SWAZILANDIA" },
	{ value: "TANZANIA", label: "TANZANIA" },
	{ value: "CHAD", label: "CHAD" },
	{ value: "TOGO", label: "TOGO" },
	{ value: "TUNEZ", label: "TUNEZ" },
	{ value: "UGANDA", label: "UGANDA" },
	{ value: "REP.DEMOCRATICA DEL CONGO", label: "REP.DEMOCRATICA DEL CONGO" },
	{ value: "ZAMBIA", label: "ZAMBIA" },
	{ value: "ZIMBABWE", label: "ZIMBABWE" },
	{ value: "ERITREA", label: "ERITREA" },
	{ value: "SANTA HELENA", label: "SANTA HELENA" },
	{ value: "REUNION", label: "REUNION" },
	{ value: "MAYOTTE", label: "MAYOTTE" },
	{ value: "SAHARA OCCIDENTAL", label: "SAHARA OCCIDENTAL" },
	{ value: "OTROS PAISES O TERRITORIOS DE AFRICA", label: "OTROS PAISES O TERRITORIOS DE AFRICA" },
	{ value: "CANADA", label: "CANADA" },
	{ value: "ESTADOS UNIDOS DE AMERICA", label: "ESTADOS UNIDOS DE AMERICA" },
	{ value: "MEXICO", label: "MEXICO" },
	{ value: "SAN PEDRO Y MIQUELON ", label: "SAN PEDRO Y MIQUELON " },
	{ value: "GROENLANDIA", label: "GROENLANDIA" },
	{
		value: "OTROS PAISES  O TERRITORIOS DE AMERICA DEL NORTE",
		label: "OTROS PAISES  O TERRITORIOS DE AMERICA DEL NORTE",
	},
	{ value: "ANTIGUA Y BARBUDA", label: "ANTIGUA Y BARBUDA" },
	{ value: "BAHAMAS", label: "BAHAMAS" },
	{ value: "BARBADOS", label: "BARBADOS" },
	{ value: "BELICE", label: "BELICE" },
	{ value: "COSTA RICA", label: "COSTA RICA" },
	{ value: "CUBA", label: "CUBA" },
	{ value: "DOMINICA", label: "DOMINICA" },
	{ value: "EL SALVADOR", label: "EL SALVADOR" },
	{ value: "GRANADA", label: "GRANADA" },
	{ value: "GUATEMALA", label: "GUATEMALA" },
	{ value: "HAITI", label: "HAITI" },
	{ value: "HONDURAS", label: "HONDURAS" },
	{ value: "JAMAICA", label: "JAMAICA" },
	{ value: "NICARAGUA", label: "NICARAGUA" },
	{ value: "PANAMA", label: "PANAMA" },
	{ value: "SAN VICENTE Y LAS GRANADINAS", label: "SAN VICENTE Y LAS GRANADINAS" },
	{ value: "REPUBLICA DOMINICANA", label: "REPUBLICA DOMINICANA" },
	{ value: "TRINIDAD Y TOBAGO", label: "TRINIDAD Y TOBAGO" },
	{ value: "SANTA LUCIA", label: "SANTA LUCIA" },
	{ value: "SAN CRISTOBAL Y NIEVES", label: "SAN CRISTOBAL Y NIEVES" },
	{ value: "ISLAS CAIMÁN", label: "ISLAS CAIMÁN" },
	{ value: "ISLAS TURCAS Y CAICOS", label: "ISLAS TURCAS Y CAICOS" },
	{ value: "ISLAS VÍRGENES DE LOS ESTADOS UNIDOS", label: "ISLAS VÍRGENES DE LOS ESTADOS UNIDOS" },
	{ value: "GUADALUPE", label: "GUADALUPE" },
	{ value: "ANTILLAS HOLANDESAS", label: "ANTILLAS HOLANDESAS" },
	{ value: "SAN MARTIN (PARTE FRANCESA)", label: "SAN MARTIN (PARTE FRANCESA)" },
	{ value: "ARUBA", label: "ARUBA" },
	{ value: "MONTSERRAT", label: "MONTSERRAT" },
	{ value: "ANGUILLA", label: "ANGUILLA" },
	{ value: "SAN BARTOLOME", label: "SAN BARTOLOME" },
	{ value: "MARTINICA", label: "MARTINICA" },
	{ value: "PUERTO RICO", label: "PUERTO RICO" },
	{ value: "BERMUDAS", label: "BERMUDAS" },
	{ value: "ISLAS VIRGENES BRITANICAS", label: "ISLAS VIRGENES BRITANICAS" },
	{
		value: "OTROS PAISES O TERRITORIOS DEL CARIBE Y AMERICA CENTRAL",
		label: "OTROS PAISES O TERRITORIOS DEL CARIBE Y AMERICA CENTRAL",
	},
	{ value: "ARGENTINA", label: "ARGENTINA" },
	{ value: "BOLIVIA", label: "BOLIVIA" },
	{ value: "BRASIL", label: "BRASIL" },
	{ value: "COLOMBIA", label: "COLOMBIA" },
	{ value: "CHILE", label: "CHILE" },
	{ value: "ECUADOR", label: "ECUADOR" },
	{ value: "GUYANA", label: "GUYANA" },
	{ value: "PARAGUAY", label: "PARAGUAY" },
	{ value: "PERU", label: "PERU" },
	{ value: "SURINAM", label: "SURINAM" },
	{ value: "URUGUAY", label: "URUGUAY" },
	{ value: "VENEZUELA", label: "VENEZUELA" },
	{ value: "GUAYANA FRANCESA", label: "GUAYANA FRANCESA" },
	{ value: "ISLAS MALVINAS", label: "ISLAS MALVINAS" },
	{ value: "OTROS PAISES O TERRITORIOS  DE SUDAMERICA", label: "OTROS PAISES O TERRITORIOS  DE SUDAMERICA" },
	{ value: "AFGANISTAN", label: "AFGANISTAN" },
	{ value: "ARABIA SAUDI", label: "ARABIA SAUDI" },
	{ value: "BAHREIN", label: "BAHREIN" },
	{ value: "BANGLADESH", label: "BANGLADESH" },
	{ value: "MYANMAR", label: "MYANMAR" },
	{ value: "CHINA", label: "CHINA" },
	{ value: "EMIRATOS ARABES UNIDOS", label: "EMIRATOS ARABES UNIDOS" },
	{ value: "FILIPINAS", label: "FILIPINAS" },
	{ value: "INDIA", label: "INDIA" },
	{ value: "INDONESIA", label: "INDONESIA" },
	{ value: "IRAQ", label: "IRAQ" },
	{ value: "IRAN", label: "IRAN" },
	{ value: "ISRAEL", label: "ISRAEL" },
	{ value: "JAPON", label: "JAPON" },
	{ value: "JORDANIA", label: "JORDANIA" },
	{ value: "CAMBOYA", label: "CAMBOYA" },
	{ value: "KUWAIT", label: "KUWAIT" },
	{ value: "LAOS", label: "LAOS" },
	{ value: "LIBANO", label: "LIBANO" },
	{ value: "MALASIA", label: "MALASIA" },
	{ value: "MALDIVAS", label: "MALDIVAS" },
	{ value: "MONGOLIA", label: "MONGOLIA" },
	{ value: "NEPAL", label: "NEPAL" },
	{ value: "OMAN", label: "OMAN" },
	{ value: "PAKISTAN", label: "PAKISTAN" },
	{ value: "QATAR", label: "QATAR" },
	{ value: "COREA", label: "COREA" },
	{ value: "COREA DEL NORTE ", label: "COREA DEL NORTE " },
	{ value: "SINGAPUR", label: "SINGAPUR" },
	{ value: "SIRIA", label: "SIRIA" },
	{ value: "SRI LANKA", label: "SRI LANKA" },
	{ value: "TAILANDIA", label: "TAILANDIA" },
	{ value: "VIETNAM", label: "VIETNAM" },
	{ value: "BRUNEI", label: "BRUNEI" },
	{ value: "ISLAS MARSHALL", label: "ISLAS MARSHALL" },
	{ value: "YEMEN", label: "YEMEN" },
	{ value: "AZERBAIYAN", label: "AZERBAIYAN" },
	{ value: "KAZAJSTAN", label: "KAZAJSTAN" },
	{ value: "KIRGUISTAN", label: "KIRGUISTAN" },
	{ value: "TADYIKISTAN", label: "TADYIKISTAN" },
	{ value: "TURKMENISTAN", label: "TURKMENISTAN" },
	{ value: "UZBEKISTAN", label: "UZBEKISTAN" },
	{ value: "ISLAS MARIANAS DEL NORTE", label: "ISLAS MARIANAS DEL NORTE" },
	{ value: "PALESTINA", label: "PALESTINA" },
	{ value: "HONG KONG", label: "HONG KONG" },
	{ value: "BHUTÁN", label: "BHUTÁN" },
	{ value: "GUAM", label: "GUAM" },
	{ value: "MACAO", label: "MACAO" },
	{ value: "OTROS PAISES O TERRITORIOS DE ASIA", label: "OTROS PAISES O TERRITORIOS DE ASIA" },
	{ value: "AUSTRALIA", label: "AUSTRALIA" },
	{ value: "FIJI", label: "FIJI" },
	{ value: "NUEVA ZELANDA", label: "NUEVA ZELANDA" },
	{ value: "PAPUA NUEVA GUINEA", label: "PAPUA NUEVA GUINEA" },
	{ value: "ISLAS SALOMON", label: "ISLAS SALOMON" },
	{ value: "SAMOA", label: "SAMOA" },
	{ value: "TONGA", label: "TONGA" },
	{ value: "VANUATU", label: "VANUATU" },
	{ value: "MICRONESIA", label: "MICRONESIA" },
	{ value: "TUVALU", label: "TUVALU" },
	{ value: "ISLAS COOK", label: "ISLAS COOK" },
	{ value: "NAURU", label: "NAURU" },
	{ value: "PALAOS", label: "PALAOS" },
	{ value: "TIMOR ORIENTAL", label: "TIMOR ORIENTAL" },
	{ value: "POLINESIA FRANCESA", label: "POLINESIA FRANCESA" },
	{ value: "ISLA NORFOLK", label: "ISLA NORFOLK" },
	{ value: "KIRIBATI", label: "KIRIBATI" },
	{ value: "NIUE", label: "NIUE" },
	{ value: "ISLAS PITCAIRN", label: "ISLAS PITCAIRN" },
	{ value: "TOKELAU", label: "TOKELAU" },
	{ value: "NUEVA CALEDONIA", label: "NUEVA CALEDONIA" },
	{ value: "WALLIS Y FORTUNA", label: "WALLIS Y FORTUNA" },
	{ value: "SAMOA AMERICANA", label: "SAMOA AMERICANA" },
	{ value: "OTROS PAISES O TERRITORIOS DE OCEANIA", label: "OTROS PAISES O TERRITORIOS DE OCEANIA" },
];

const FormularioInformacionPersonal = ({ formulario }) => {
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const isLaredo = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20132377783" ? true : false;
	const isExplomin = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20501523837" ? true : false;
	const maccaferriDePeru = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20418710757" ? true : false;
	const maccaferriConstruction = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20521749378" ? true : false;

	return (
		<Form layout="vertical" form={formulario}>

			{isLaredo && getUsuarioAutenticado().empresaSeleccionada.colaborador.estado === "ONBOARDING"
				&& (
					<DatosPersonalesColaboradoraActivo form={formulario} />
				)
			}

			{!isLaredo && getUsuarioAutenticado().empresaSeleccionada.colaborador.estado === "ONBOARDING" && (
				<div>
					<DatosPersonales form={formulario} />
					{!maccaferriDePeru && !maccaferriConstruction && <RegimenPensionario form={formulario} />}
					{!maccaferriDePeru && !maccaferriConstruction && <CuentaBancaria form={formulario} />}
					{!isExplomin && !maccaferriDePeru && !maccaferriConstruction && <CuentaCTS form={formulario} />}
					<DomicilioActual form={formulario} />
					<DatosNacimiento form={formulario} />
					<ContactoEmergencia />
				</div>
			)}
			{getUsuarioAutenticado().empresaSeleccionada.colaborador.estado === "ACTIVO" && (
				<div>
					<DatosPersonalesColaboradoraActivo form={formulario} />
				</div>
			)}

			<Botones form={formulario} />
		</Form>
	);
};

const Botones = ({ form }) => {
	const [loading, setLoading] = useState(false);
	const firmaHolografa = useAtomValue(firmaHolografaAtom);
	const huellaDactilar = useAtomValue(huellaDactilarAtom);
	const fotoPersonal = useAtomValue(fotoPersonalAtom);
	const setPasoActual = useSetAtom(pasoActualAtom);
	const { actualizarEstadoFormulario, getUsuarioAutenticado } = useContext(SecurityContext);
	const history = useHistory();
	const isExplomin = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20501523837" ? true : false;

	const onClickBtnContinuar = () => {
		form.validateFields()
			.then(async (formulario) => {
				setLoading(true);
				const informacionPersonal = {
					datosPersonales: formulario.datosPersonales,
					domicilioActual: formulario.domicilioActual,
					datosNacimiento: formulario.datosNacimiento,
					regimenPensionario: formulario.regimenPensionario,
					cuentaHaberes: formulario.cuentaHaberes,
					cuentaCTS: formulario.cuentaCTS,
					contactoEmergencia: formulario.contactoEmergencia,
				};

				const formData = new FormData();
				formData.append(
					"informacionPersonal",
					new Blob([JSON.stringify(informacionPersonal)], {
						type: "application/json",
					})
				);

				formData.append("firmaHolografa", formulario.firmaholografa.fileList[0]);

				formData.append("huellaDactilar", huellaDactilar);
				if (!getUsuarioAutenticado().empresaSeleccionada?.colaborador?.urlFoto) {
					formData.append("fotoPersonal", fotoPersonal);
				} else {
					formData.append("fotoPersonal", null);
				}

				try {
					await OnboardingService.completarInformacionPersonal(formData);
					message.success("Información personal registrada correctamente.");
					actualizarEstadoFormulario();
					// setLoading(false);
					if (getUsuarioAutenticado().empresaSeleccionada.colaborador.informacionPersonalCompletado) {
						window.location.reload();
					} else if (getUsuarioAutenticado().empresaSeleccionada.colaborador.estado === "ACTIVO") {
						history.push("/mis-documentos");
					}

					setPasoActual(1);
				} catch (error) {
					message.error("No se pudo guardar su información personal. Intente nuevamente");
					setLoading(false);
				}
			})
			.catch((info) => {
				console.error("Validate Failed:", info);
				message.error("Complete los datos obligatorios.");
			});
	};

	return (
		<Row gutter={16}>
			<Col span={24}>
				<div style={{ display: "flex", justifyContent: "center" }}>
					<Popconfirm
						placement="top"
						title={
							<span>
								Recuerda que la información proporcionada debe ser veráz <br /> ¿Confirma que la
								información es correcta?
							</span>
						}
						onConfirm={onClickBtnContinuar}
						okText="Si, continuar"
						cancelText="Cancelar"
						okButtonProps={{ loading }}
					>
						<Button
							type="primary"
							size="large"
							style={{ borderRadius: "16px", padding: "0px 50px" }}
						// loading={loading}
						// onClick={onClickBtnContinuar}
						>
							Continuar <RightOutlined />
						</Button>
					</Popconfirm>
				</div>
			</Col>
		</Row>
	);
};

const DatosPersonalesColaboradoraActivo = ({ form }) => {
	const [firmaHolografa, setFirmaHolografa] = useAtom(firmaHolografaAtom);
	const [huellaDactilar, setHuellaDactilar] = useAtom(huellaDactilarAtom);
	const [fotoPersonal, setFotoPersonal] = useAtom(fotoPersonalAtom);
	const { getUsuarioAutenticado } = useContext(SecurityContext);

	const [openModalSubirFirma, setOpenModalSubirFirma] = useState(false);

	const isLaredo = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20132377783" ? true : false;
	const isExplomin = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20501523837" ? true : false;
	const ismaccaferriDePeru = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20418710757" ? true : false;
	const ismaccaferriConstruction = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20521749378" ? true : false;
	const isPSP = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20601257280" ? true : false;

	const onRemoveFirmaHolografa = () => {
		setFirmaHolografa([]);

		return {
			firmaHolografa: [],
		};
	}

	const onClickBtnAceptarModalSubirFirma = (file) => {
		if (file && file.length > 0) {
			setFirmaHolografa([...file]);
			form.setFieldsValue({ firmaholografa: { fileList: file } });

		}
		setOpenModalSubirFirma(false);
	}

	const propsFirma = {
		onRemove: (_) => {
			setFirmaHolografa([]);

			return {
				firmaHolografa: [],
			};
		},
		beforeUpload: (file) => {
			if (file.type !== "image/png" && file.type !== "image/jpeg") {
				message.error(`${file.name} no es un archivo png ó jpeg`);
			} else {
				setFirmaHolografa(file);
				return false;
			}
		},
		firmaHolografa,
	};

	const propsHuella = {
		onRemove: (_) => {
			setHuellaDactilar([]);

			return {
				huellaDactilar: [],
			};
		},
		beforeUpload: (file) => {
			if (file.type !== "image/png" && file.type !== "image/jpeg") {
				message.error(`${file.name} no es un archivo png ó jpeg`);
			} else {
				setHuellaDactilar(file);
				return false;
			}
		},
		huellaDactilar,
	};

	const propsFoto = {
		onRemove: (_) => {
			setFotoPersonal([]);

			return {
				fotoPersonal: [],
			};
		},
		beforeUpload: (file) => {
			if (file.type !== "image/png" && file.type !== "image/jpeg") {
				message.error(`${file.name} no es un archivo png ó jpeg`);
			} else {
				setFotoPersonal(file);
				return false;
			}
		},
		fotoPersonal,
	};

	return (
		<>
			<Divider plain style={{ color: "#457b9d", borderColor: "#219ebc" }}>
				<Space>
					<EditOutlined /> Información personal
				</Space>
			</Divider>
			<Row gutter={16}>
				<Col span={8}>
					<Form.Item
						name="firmaholografa"
						label="Configurar Firma Hológrafa"
						valuePropName="firmaholografa"
						rules={[
							{
								required: true,
								message: "Suba una imágen de su firma hológrafa",
							},
						]}
					>
						<Row style={{ width: "100%" }}>
							<Button
								onClick={() => setOpenModalSubirFirma(true)}
								block
								icon={<UploadOutlined />}
								style={{ flex: 1 }}
							>
								Subir Firma Hológrafa
							</Button>
							<Upload
								fileList={firmaHolografa}
								style={{ width: "100%" }}
								onRemove={onRemoveFirmaHolografa}
							/>
						</Row>
					</Form.Item>
				</Col>

				{!ismaccaferriDePeru && !ismaccaferriConstruction && !isLaredo && (
					<Col span={8}>
						<Form.Item
							// name="huellaDactilar"
							// label="Huella Dactilar"
							// valuePropName="huellaDigital"
							// rules={[
							// 	{
							// 		required: true,
							// 		message: "Suba una imágen de su huella dactilar",
							// 	},
							// ]}
							name="huellaDactilar"
							label={
								<span>
									<span style={{ color: "#ff4d4f", marginRight: "4px" }}>*</span>
									Huella Dactilar
								</span>
							}
							valuePropName="huellaDigital"
							validateStatus={huellaDactilar && huellaDactilar.length == 0 ? "error" : ""}
							help={
								huellaDactilar && huellaDactilar.length == 0 && "Suba una imagen de su huella dactilar"
							}
							rules={[
								{
									validator: async (rule, value) => {
										if (!huellaDactilar || huellaDactilar.length === 0) {
											return Promise.reject("Suba una imagen de su huella dactilar");
										}
										return Promise.resolve();
									},
								},
							]}
						>
							<ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
								<Upload {...propsHuella} maxCount={1} accept={["image/*"]}>
									<Button block icon={<UploadOutlined />}>
										Subir Huella Dactilar
									</Button>
								</Upload>
							</ImgCrop>
						</Form.Item>
					</Col>
				)}

				{!getUsuarioAutenticado().empresaSeleccionada?.colaborador?.urlFoto && !isExplomin && !isLaredo && (
					<Col span={8}>
						<Form.Item
							// name="fotoColaborador"
							// label="Foto personal"
							// valuePropName="fotoColaborador"
							// rules={[
							// 	{
							// 		required: true,
							// 		message: "Suba una foto.",
							// 	},
							// ]}
							name="fotoColaborador"
							label={
								<span>
									<span style={{ color: "#ff4d4f", marginRight: "4px" }}>*</span>
									Foto Personal
								</span>
							}
							valuePropName="fotoColaborador"
							validateStatus={fotoPersonal && fotoPersonal.length == 0 ? "error" : ""}
							help={fotoPersonal && fotoPersonal.length == 0 && "Suba una imagen de su foto"}
							rules={[
								{
									validator: async (rule, value) => {
										if (!fotoPersonal || fotoPersonal.length === 0) {
											return Promise.reject("Suba una imagen de su foto");
										}
										return Promise.resolve();
									},
								},
							]}
						>
							<ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
								<Upload {...propsFoto} maxCount={1} accept={["image/*"]}>
									<Button block icon={<UploadOutlined />}>
										Subir Foto
									</Button>
								</Upload>
							</ImgCrop>
						</Form.Item>
					</Col>
				)}

				{!getUsuarioAutenticado().empresaSeleccionada?.colaborador?.urlFoto && isExplomin && (
					<Col span={8}>
						<Form.Item
							name="fotoColaborador"
							label="Foto personal"
							valuePropName="fotoColaborador"
							rules={[
								{
									required: false,
									//message: "Suba una foto.",
								},
							]}
						// name="fotoColaborador"
						// label={
						// 	<span>
						// 		<span style={{ color: "#ff4d4f", marginRight: "4px" }}>*</span>
						// 		Foto Personal
						// 	</span>
						// }
						// valuePropName="fotoColaborador"
						// validateStatus={fotoPersonal && fotoPersonal.length == 0 ? "error" : ""}
						// help={fotoPersonal && fotoPersonal.length == 0 && "Suba una imagen de su foto"}
						// rules={[
						// 	{
						// 		validator: async (rule, value) => {
						// 			if (!firmaHolografa || firmaHolografa.length === 0) {
						// 				return Promise.reject("Suba una imagen de su foto");
						// 			}
						// 			return Promise.resolve();
						// 		},
						// 	},
						// ]}
						>
							<ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
								<Upload {...propsFoto} maxCount={1} accept={["image/*"]}>
									<Button block icon={<UploadOutlined />}>
										Subir Foto
									</Button>
								</Upload>
							</ImgCrop>
						</Form.Item>
					</Col>
				)}
			</Row>
			<ModalSubirFirma
				visible={openModalSubirFirma}
				onAceptar={onClickBtnAceptarModalSubirFirma}
				onCerrar={() => setOpenModalSubirFirma(false)} />
		</>
	);
};

const DatosPersonales = ({ form }) => {
	const [firmaHolografa, setFirmaHolografa] = useAtom(firmaHolografaAtom);
	const [huellaDactilar, setHuellaDactilar] = useAtom(huellaDactilarAtom);
	const [fotoPersonal, setFotoPersonal] = useAtom(fotoPersonalAtom);
	const { getUsuarioAutenticado } = useContext(SecurityContext);

	const [openModalSubirFirma, setOpenModalSubirFirma] = useState(false);

	const isExplomin = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20501523837" ? true : false;
	const maccaferriDePeru = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20418710757" ? true : false;
	const maccaferriConstruction = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20521749378" ? true : false;
	const isPSP = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20601257280" ? true : false;

	const onRemoveFirmaHolografa = () => {
		setFirmaHolografa([]);

		return {
			firmaHolografa: [],
		};
	}

	const onClickBtnAceptarModalSubirFirma = (file) => {
		if (file && file.length > 0) {
			setFirmaHolografa([...file]);
			form.setFieldsValue({ firmaholografa: { fileList: file } });

		}
		setOpenModalSubirFirma(false);
	}

	// const propsFirma = {
	// 	onRemove: (_) => {
	// 		setFirmaHolografa([]);

	// 		return {
	// 			firmaHolografa: [],
	// 		};
	// 	},
	// 	beforeUpload: (file) => {
	// 		if (file.type !== "image/png" && file.type !== "image/jpeg") {
	// 			message.error(`${file.name} no es un archivo png ó jpeg`);
	// 		} else {
	// 			console.log("ingreso aquí....", file);
	// 			setFirmaHolografa(file);
	// 			return false;
	// 		}
	// 	},
	// 	firmaHolografa,
	// };

	const propsHuella = {
		onRemove: (_) => {
			setHuellaDactilar([]);

			return {
				huellaDactilar: [],
			};
		},
		beforeUpload: (file) => {
			if (file.type !== "image/png" && file.type !== "image/jpeg") {
				message.error(`${file.name} no es un archivo png ó jpeg`);
			} else {
				setHuellaDactilar(file);
				return false;
			}
		},
		huellaDactilar,
	};

	const propsFoto = {
		onRemove: (_) => {
			setFotoPersonal([]);

			return {
				fotoPersonal: [],
			};
		},
		beforeUpload: (file) => {
			if (file.type !== "image/png" && file.type !== "image/jpeg") {
				message.error(`${file.name} no es un archivo png ó jpeg`);
			} else {
				setFotoPersonal(file);
				return false;
			}
		},
		fotoPersonal,
	};

	return (
		<>
			<Divider plain style={{ color: "#457b9d", borderColor: "#219ebc" }}>
				<Space>
					<EditOutlined /> Información personal
				</Space>
			</Divider>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item
						label="Tipo Documento Identidad"
						name={["datosPersonales", "tipoDocumentoIdentidad"]}
						rules={[
							{
								required: true,
								message: "Selecciona el tipo de documento",
							},
						]}
					>
						<Select allowClear>
							<Select.Option value={TipoDocumentoIdentidad.DNI.valor}>
								{TipoDocumentoIdentidad.DNI.nombre}
							</Select.Option>
							<Select.Option value={TipoDocumentoIdentidad.CARNET_EXTRANJERIA.valor}>
								{TipoDocumentoIdentidad.CARNET_EXTRANJERIA.descripcion}
							</Select.Option>
							<Select.Option value={TipoDocumentoIdentidad.OTRO_DOCUMENTO.valor}>
								{TipoDocumentoIdentidad.OTRO_DOCUMENTO.descripcion}
							</Select.Option>
						</Select>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="Número de Documento Identidad"
						name={["datosPersonales", "numeroDocumentoIdentidad"]}
						rules={[
							{
								required: true,
								message: "Escribe el número de tu documento de identidad",
								whitespace: true,
							},
						]}
					>
						<Input autoComplete="off" />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item
						label="Apellido Paterno"
						name={["datosPersonales", "apellidoPaterno"]}
						rules={[
							{
								required: true,
								message: "Ingresa tu apellido paterno",
							},
						]}
					>
						<Input />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="Apellido Materno"
						name={["datosPersonales", "apellidoMaterno"]}
						rules={[
							{
								required: true,
								message: "Ingresa tu apellido materno",
							},
						]}
					>
						<Input />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={24}>
					<Form.Item
						label="Nombres"
						name={["datosPersonales", "nombres"]}
						rules={[
							{
								required: true,
								message: "Ingresa tus nombres",
							},
						]}
					>
						<Input />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item
						label="Género"
						name={["datosPersonales", "genero"]}
						rules={[
							{
								required: true,
								message: "Selecciona tu género",
							},
						]}
					>
						<Select>
							<Select.Option value="FEMENINO">FEMENINO</Select.Option>
							<Select.Option value="MASCULINO">MASCULINO</Select.Option>
						</Select>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="Estado Civil"
						name={["datosPersonales", "estadoCivil"]}
						rules={[
							{
								required: true,
								message: "Selecciona tu estado civil",
							},
						]}
					>
						<Select>
							<Select.Option value="SOLTERO">SOLTERO</Select.Option>
							<Select.Option value="CASADO">CASADO</Select.Option>
							<Select.Option value="CONVIVIENTE">CONVIVIENTE</Select.Option>
							<Select.Option value="DIVORCIADO">DIVORCIADO</Select.Option>
							<Select.Option value="VIUDO">VIUDO</Select.Option>
						</Select>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item
						label="Correo Electrónico"
						name={["datosPersonales", "correoElectronico"]}
						rules={[
							{
								required: true,
								message: "Ingresa tu correo electrónico",
							},
						]}
					>
						<Input />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label="Celular" name={["datosPersonales", "celular"]}>
						<Input />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={8}>
					<Form.Item
						name="firmaholografa"
						label="Configurar Firma Hológrafa"
						valuePropName="firmaholografa"
						rules={[
							{
								required: true,
								message: "Suba una imágen de su firma hológrafa",
							},
						]}
					>
						<Row style={{ width: "100%" }}>
							<Button
								onClick={() => setOpenModalSubirFirma(true)}
								block
								icon={<UploadOutlined />}
								style={{ flex: 1 }}
							>
								Subir Firma Hológrafa
							</Button>
							<Upload
								fileList={firmaHolografa}
								style={{ width: "100%" }}
								onRemove={onRemoveFirmaHolografa}
							/>
						</Row>
					</Form.Item>
					{/* <Form.Item
						name="firmaHolografa"
						label={
							<span>
								<span style={{ color: "#ff4d4f", marginRight: "4px" }}>*</span>
								Firma Hológrafa
							</span>
						}
						valuePropName="firmaHolografa"
						validateStatus={firmaHolografa && firmaHolografa.length == 0 ? "error" : ""}
						help={firmaHolografa && firmaHolografa.length == 0 && "Suba una imagen de su firma hológrafa"}
						rules={[
							{
								validator: async (rule, value) => {
									if (!firmaHolografa || firmaHolografa.length === 0) {
										return Promise.reject("Suba una imagen de su firma hológrafa");
									}
									return Promise.resolve();
								},
							},
						]}
					>
						<ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
							<Upload {...propsFirma} maxCount={1} accept={["image/*"]}>
								<Button block icon={<UploadOutlined />}>
									Subir Firma Hológrafa
								</Button>
							</Upload>
						</ImgCrop>
					</Form.Item> */}
				</Col>

				{!maccaferriDePeru && !maccaferriConstruction && (
					<Col span={8}>
						<Form.Item
							name="huellaDactilar"
							label={
								<span>
									<span style={{ color: "#ff4d4f", marginRight: "4px" }}>*</span>
									Huella Dactilar
								</span>
							}
							valuePropName="huellaDigital"
							validateStatus={huellaDactilar && huellaDactilar.length == 0 ? "error" : ""}
							help={
								huellaDactilar && huellaDactilar.length == 0 && "Suba una imagen de su huella dactilar"
							}
							rules={[
								{
									validator: async (rule, value) => {
										if (!huellaDactilar || huellaDactilar.length === 0) {
											return Promise.reject("Suba una imagen de su huella dactilar");
										}
										return Promise.resolve();
									},
								},
							]}
						>
							<ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
								<Upload {...propsHuella} maxCount={1} accept={["image/*"]}>
									<Button block icon={<UploadOutlined />}>
										Subir Huella Dactilar
									</Button>
								</Upload>
							</ImgCrop>
						</Form.Item>
					</Col>
				)}
				<Col span={8}>
					<Form.Item
						name="fotoColaborador"
						label={
							<span>
								<span style={{ color: "#ff4d4f", marginRight: "4px" }}>*</span>
								Foto Personal
							</span>
						}
						valuePropName="fotoColaborador"
						validateStatus={fotoPersonal && fotoPersonal.length == 0 ? "error" : ""}
						help={fotoPersonal && fotoPersonal.length == 0 && "Suba una imagen de su foto"}
						rules={[
							{
								validator: async (rule, value) => {
									if (!fotoPersonal || fotoPersonal.length === 0) {
										return Promise.reject("Suba una imagen de su foto");
									}
									return Promise.resolve();
								},
							},
						]}
					>
						<ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
							<Upload {...propsFoto} maxCount={1} accept={["image/*"]}>
								<Button block icon={<UploadOutlined />}>
									Subir Foto
								</Button>
							</Upload>
						</ImgCrop>
					</Form.Item>
				</Col>
			</Row>
			<ModalSubirFirma
				visible={openModalSubirFirma}
				onAceptar={onClickBtnAceptarModalSubirFirma}
				onCerrar={() => setOpenModalSubirFirma(false)} />
		</>
	);
};

const DatosNacimiento = ({ form }) => {
	const [departamentos, setDepartamentos] = useAtom(departamentoNacimientoAtom);
	const [mostrarSelect, setMostrarSelect] = useAtom(mostrarCamposPorPaisAtom);
	const [provincias, setProvincias] = useAtom(provinciaNacimientoAtom);
	const [distritos, setDistritos] = useAtom(distritoNacimientoAtom);

	useEffect(() => {
		async function cargarDatos() {
			const departamentos = await UbigeoService.listarDepartamentos();
			setDepartamentos(departamentos);
		}

		cargarDatos();
	}, []);

	const onChangePais = (value) => {
		if (value == "PERU") {
			setMostrarSelect(true);
		} else {
			setMostrarSelect(false);
		}
	};
	const onChangeDepartamento2 = async (value) => {
		const formData = new FormData();
		formData.append("departamento", value);
		const provincias = await UbigeoService.listarProvincias(formData);
		setProvincias(provincias);

		form.setFieldsValue({
			datosNacimiento: {
				provincia: provincias[0].provincia,
			},
		});

		const formDataDistrito = new FormData();
		formDataDistrito.append("provincia", provincias[0].provincia);

		const distritos = await UbigeoService.listarDistritos(formDataDistrito);
		setDistritos(distritos);

		form.setFieldsValue({
			datosNacimiento: {
				distrito: distritos[0].distrito,
			},
		});
	};
	const onChangeProvincia2 = async (value) => {
		const formData = new FormData();
		formData.append("provincia", value);
		const distritos = await UbigeoService.listarDistritos(formData);
		setDistritos(distritos);

		form.setFieldsValue({
			datosNacimiento: {
				distrito: distritos[0].distrito,
			},
		});
	};

	return (
		<>
			<Divider plain style={{ color: "#457b9d", borderColor: "#219ebc" }}>
				<Space>
					<IdcardOutlined /> Lugar de nacimiento
				</Space>
			</Divider>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item label="País" name={["datosNacimiento", "pais"]} rules={[{ required: true }]}>
						<Select
							style={{ width: "100%" }}
							allowClear
							showSearch
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={onChangePais}
						>
							{lstPaises !== undefined &&
								lstPaises?.map((objeto) => (
									<Select.Option key={objeto.value} value={objeto.value}>
										{objeto.label}
									</Select.Option>
								))}
						</Select>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="Nacionalidad"
						name={["datosNacimiento", "nacionalidad"]}
						rules={[{ required: true }]}
					>
						<Input />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item
						label="Fecha Nacimiento"
						name={["datosNacimiento", "fechaNacimiento"]}
						rules={[{ required: true }]}
					>
						<DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
					</Form.Item>
				</Col>
				<Col span={12}>
					{!mostrarSelect && (
						<Form.Item
							label="Departamento"
							name={["datosNacimiento", "departamento"]}
							rules={[{ required: true }]}
						>
							<Input />
						</Form.Item>
					)}
					{mostrarSelect && (
						<Form.Item
							label="Departamento"
							name={["datosNacimiento", "departamento"]}
							rules={[{ required: true }]}
						>
							<Select
								style={{ width: "100%" }}
								allowClear
								showSearch
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								onChange={onChangeDepartamento2}
							>
								{departamentos !== undefined &&
									departamentos?.map((departamento) => (
										<Select.Option key={departamento.nombre} value={departamento.nombre}>
											{departamento.nombre}
										</Select.Option>
									))}
							</Select>
						</Form.Item>
					)}
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={12}>
					{!mostrarSelect && (
						<Form.Item
							label="Provincia"
							name={["datosNacimiento", "provincia"]}
							rules={[{ required: true }]}
						>
							<Input />
						</Form.Item>
					)}
					{mostrarSelect && (
						<Form.Item
							label="Provincia"
							name={["datosNacimiento", "provincia"]}
							rules={[{ required: true }]}
						>
							<Select
								style={{ width: "100%" }}
								allowClear
								showSearch
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								onChange={onChangeProvincia2}
							>
								{provincias !== undefined &&
									provincias?.map((objeto) => (
										<Select.Option key={objeto.provincia} value={objeto.provincia}>
											{objeto.provincia}
										</Select.Option>
									))}
							</Select>
						</Form.Item>
					)}
				</Col>
				<Col span={12}>
					{!mostrarSelect && (
						<Form.Item label="Distrito" name={["datosNacimiento", "distrito"]} rules={[{ required: true }]}>
							<Input />
						</Form.Item>
					)}
					{mostrarSelect && (
						<Form.Item label="Distrito" name={["datosNacimiento", "distrito"]} rules={[{ required: true }]}>
							<Select
								style={{ width: "100%" }}
								allowClear
								showSearch
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
							//onChange={onChangeProvincia2}
							>
								{distritos !== undefined &&
									distritos?.map((objeto) => (
										<Select.Option key={objeto.distrito} value={objeto.distrito}>
											{objeto.distrito}
										</Select.Option>
									))}
							</Select>
						</Form.Item>
					)}
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={24}>
					<Form.Item label="Direccion" name={["datosNacimiento", "direccion"]} rules={[{ required: true }]}>
						<Input />
					</Form.Item>
				</Col>
			</Row>
		</>
	);
};

const DomicilioActual = ({ form }) => {
	const [departamentos, setDepartamentos] = useAtom(departamentoAtom);
	const [provincias, setProvincias] = useAtom(provinciaAtom);
	const [distritos, setDistritos] = useAtom(distritoAtom);
	//const [provinciaValue, setProvinciaValue] = useState(provincias[0]);

	useEffect(() => {
		async function cargarDatos() {
			const departamentos = await UbigeoService.listarDepartamentos();
			setDepartamentos(departamentos);
		}

		cargarDatos();
	}, []);

	const onChangeDepartamento = async (value) => {
		const formData = new FormData();
		formData.append("departamento", value);
		const provincias = await UbigeoService.listarProvincias(formData);
		setProvincias(provincias);
		//setProvinciaValue(provincias[0].provincia);
		form.setFieldsValue({
			domicilioActual: {
				provincia: provincias[0].provincia,
			},
		});

		const formDataDistrito = new FormData();
		formDataDistrito.append("provincia", provincias[0].provincia);

		const distritos = await UbigeoService.listarDistritos(formDataDistrito);
		setDistritos(distritos);

		form.setFieldsValue({
			domicilioActual: {
				distrito: distritos[0].distrito,
			},
		});
	};

	const onChangeProvincia = async (value) => {
		const formData = new FormData();
		formData.append("provincia", value);
		const distritos = await UbigeoService.listarDistritos(formData);
		setDistritos(distritos);

		form.setFieldsValue({
			domicilioActual: {
				distrito: distritos[0].distrito,
			},
		});
	};

	return (
		<>
			<Divider plain style={{ color: "#457b9d", borderColor: "#219ebc" }}>
				<Space>
					<HomeOutlined /> Domicilio Actual
				</Space>
			</Divider>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item
						label="Direccion"
						name={["domicilioActual", "direccion"]}
						rules={[
							{
								required: true,
								message: "Ingresa una dirección",
							},
						]}
					>
						<Input />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="Departamento"
						name={["domicilioActual", "departamento"]}
						rules={[
							{
								required: true,
								message: "Ingresa el departamento",
							},
						]}
					>
						{/* <Input /> */}
						<Select
							style={{ width: "100%" }}
							allowClear
							showSearch
							//placeholder="Ingrese el nombre del colaborador"
							//onSearch={onSearchColaboradores}
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							//onSelect={onSelectColaborador}
							onChange={onChangeDepartamento}
						>
							{departamentos !== undefined &&
								departamentos?.map((departamento) => (
									<Select.Option key={departamento.nombre} value={departamento.nombre}>
										{departamento.nombre}
									</Select.Option>
								))}
						</Select>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item
						label="Provincia"
						name={["domicilioActual", "provincia"]}
						rules={[
							{
								required: true,
								message: "Ingresa la provincia",
							},
						]}
					>
						{/* <Input /> */}
						<Select
							style={{ width: "100%" }}
							allowClear
							showSearch
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={onChangeProvincia}
						//value={provinciaValue}
						>
							{provincias !== undefined &&
								provincias?.map((objeto) => (
									<Select.Option key={objeto.provincia} value={objeto.provincia}>
										{objeto.provincia}
									</Select.Option>
								))}
						</Select>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="Distrito"
						name={["domicilioActual", "distrito"]}
						rules={[
							{
								required: true,
								message: "Ingresa el distrito",
							},
						]}
					>
						{/* <Input /> */}
						<Select
							style={{ width: "100%" }}
							allowClear
							showSearch
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
						//onChange={onChangeProvincia}
						>
							{distritos !== undefined &&
								distritos?.map((objeto) => (
									<Select.Option key={objeto.distrito} value={objeto.distrito}>
										{objeto.distrito}
									</Select.Option>
								))}
						</Select>
					</Form.Item>
				</Col>
			</Row>
		</>
	);
};

const RegimenPensionario = ({ form }) => {
	const tipoRegimenPensionario = Form.useWatch(["regimenPensionario", "tipo"], form);
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const isExplomin = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20501523837" ? true : false;
	return (
		<>
			<Divider plain style={{ color: "#457b9d", borderColor: "#219ebc" }}>
				<Space>
					<RiseOutlined /> Regimen Pensionario
				</Space>
			</Divider>
			{!isExplomin ? (
				<Row gutter={16}>
					<Col span={8}>
						<Form.Item label="Tipo" name={["regimenPensionario", "tipo"]} rules={[{ required: true }]}>
							<Select allowClear>
								<Select.Option value="NINGUNO">NINGUNO</Select.Option>
								<Select.Option value="AFP">AFP</Select.Option>
								<Select.Option value="ONP">ONP</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label="CUSPP AFP"
							name={["regimenPensionario", "cusppAfp"]}
							rules={[{ required: tipoRegimenPensionario === "AFP" ? true : false }]}
						>
							<Input disabled={tipoRegimenPensionario !== "AFP"} />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label="Nombre AFP"
							name={["regimenPensionario", "nombreAfp"]}
							rules={[{ required: tipoRegimenPensionario === "AFP" ? true : false }]}
						>
							<Input disabled={tipoRegimenPensionario !== "AFP"} />
						</Form.Item>
					</Col>
				</Row>
			) : (
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item label="Tipo" name={["regimenPensionario", "tipo"]} rules={[{ required: true }]}>
							<Select allowClear>
								<Select.Option value="NINGUNO">NINGUNO</Select.Option>
								<Select.Option value="AFP">AFP</Select.Option>
								<Select.Option value="ONP">ONP</Select.Option>
							</Select>
						</Form.Item>
					</Col>
				</Row>
			)}
		</>
	);
};

const CuentaBancaria = ({ form }) => {
	const existeCuenta = Form.useWatch(["cuentaHaberes", "exist"], form);

	return (
		<>
			<Divider plain style={{ color: "#457b9d", borderColor: "#219ebc" }}>
				<Space>
					<WalletOutlined /> Cuenta Haberes
				</Space>
			</Divider>
			<Form.Item
				name={["cuentaHaberes", "exist"]}
				rules={[{ required: true, message: "Por favor seleccione una opcion" }]}
			>
				<Radio.Group>
					<Radio value={true}>Tengo Cuenta</Radio>
					<Radio value={false}>Crear Cuenta</Radio>
				</Radio.Group>
			</Form.Item>
			<Row gutter={16}>
				<Col span={12}>
					{/* <Form.Item label="Banco" name={["cuentaHaberes", "banco"]} rules={[{ required: existeCuenta }]}> */}
					<Form.Item label="Banco" name={["cuentaHaberes", "banco"]} rules={[{ required: true }]}>
						{/* <Input disabled={!existeCuenta} /> */}
						<Select>
							<Select.Option value="BANCO CONTINENTAL">BANCO CONTINENTAL</Select.Option>
							<Select.Option value="BANCO DE CREDITO">BANCO DE CREDITO</Select.Option>
							<Select.Option value="BANCO INTERBANK">BANCO INTERBANK</Select.Option>
							<Select.Option value="BANCO SCOTIABANK">BANCO SCOTIABANK</Select.Option>
							<Select.Option value="BANCO CITIBANK">BANCO CITIBANK</Select.Option>
							<Select.Option value="BANCO HSBC">BANCO HSBC</Select.Option>
							<Select.Option value="BANCO DE LA NACION">BANCO DE LA NACION</Select.Option>
							<Select.Option value="BANCO DE COMERCIO">BANCO DE COMERCIO</Select.Option>
							<Select.Option value="BANCO FINANCIERO">BANCO FINANCIERO</Select.Option>
							<Select.Option value="BANBIF">BANBIF</Select.Option>
							<Select.Option value="BANCO FALABELLA">BANCO FALABELLA</Select.Option>
							<Select.Option value="BANCO RIPLEY">BANCO RIPLEY</Select.Option>
						</Select>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="Moneda"
						name={["cuentaHaberes", "tipoMoneda"]}
						// rules={[{ required: existeCuenta }]}
						rules={[{ required: true }]}
					>
						{/* <Input disabled={!existeCuenta} /> */}
						<Select>
							<Select.Option value="SOLES">SOLES</Select.Option>
							<Select.Option value="DOLARES">DOLARES</Select.Option>
						</Select>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="Cuenta"
						name={["cuentaHaberes", "nroCuenta"]}
						rules={[{ required: existeCuenta }]}
					>
						<Input disabled={!existeCuenta} />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="Cuenta CCI"
						name={["cuentaHaberes", "nroCuentaCCI"]}
						rules={[{ required: existeCuenta }]}
					>
						<Input disabled={!existeCuenta} />
					</Form.Item>
				</Col>
			</Row>
		</>
	);
};

const CuentaCTS = ({ form }) => {
	const existeCuenta = Form.useWatch(["cuentaCTS", "exist"], form);

	return (
		<>
			<Divider plain style={{ color: "#457b9d", borderColor: "#219ebc" }}>
				<Space>
					<WalletOutlined /> Cuenta CTS
				</Space>
			</Divider>
			<Form.Item
				name={["cuentaCTS", "exist"]}
				rules={[{ required: true, message: "Por favor seleccione una opcion" }]}
			>
				<Radio.Group style={{ alignItems: "center" }}>
					{/* <Radio value={true}>Tengo Cuenta</Radio> */}
					<Radio value={false}>Crear Cuenta</Radio>
					<Radio value={true}>Ninguno</Radio>
				</Radio.Group>
			</Form.Item>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item label="Banco" name={["cuentaCTS", "banco"]} rules={[{ required: !existeCuenta }]}>
						{/* <Form.Item label="Banco" name={["cuentaCTS", "banco"]} rules={[{ required: existeCuenta }]}> */}
						{/* <Input disabled={!existeCuenta} /> */}
						<Select disabled={existeCuenta}>
							<Select.Option value="BANCO CONTINENTAL">BANCO CONTINENTAL</Select.Option>
							<Select.Option value="BANCO DE CREDITO">BANCO DE CREDITO</Select.Option>
							<Select.Option value="BANCO INTERBANK">BANCO INTERBANK</Select.Option>
							<Select.Option value="BANCO SCOTIABANK">BANCO SCOTIABANK</Select.Option>
							<Select.Option value="BANCO CITIBANK">BANCO CITIBANK</Select.Option>
							<Select.Option value="BANCO HSBC">BANCO HSBC</Select.Option>
							<Select.Option value="BANCO DE LA NACION">BANCO DE LA NACION</Select.Option>
							<Select.Option value="BANCO DE COMERCIO">BANCO DE COMERCIO</Select.Option>
							<Select.Option value="BANCO FINANCIERO">BANCO FINANCIERO</Select.Option>
							<Select.Option value="BANBIF">BANBIF</Select.Option>
							<Select.Option value="BANCO FALABELLA">BANCO FALABELLA</Select.Option>
							<Select.Option value="BANCO RIPLEY">BANCO RIPLEY</Select.Option>
							<Select.Option value="CAJA MUN. AREQUIPA">CAJA MUN. AREQUIPA</Select.Option>
							<Select.Option value="CAJA MUN. CUSCO">CAJA MUN. CUSCO</Select.Option>
							<Select.Option value="CAJA METROPOL. LIMA">CAJA METROPOL. LIMA</Select.Option>
							<Select.Option value="CAJA MUN. PIURA">CAJA MUN. PIURA</Select.Option>
							<Select.Option value="CAJA TRUJILLO">CAJA TRUJILLO</Select.Option>
							<Select.Option value="MI BANCO">MI BANCO</Select.Option>
							<Select.Option value="FINANCIERA CONFIANZA">FINANCIERA CONFIANZA</Select.Option>
							<Select.Option value="CAJA RURAL SR. LUREN">CAJA RURAL SR. LUREN</Select.Option>
							<Select.Option value="CAJA MUN. HUANCAYO">CAJA MUN. HUANCAYO</Select.Option>
							<Select.Option value="CAJA SULLANA">CAJA SULLANA</Select.Option>
							<Select.Option value="CENCOSUD">CENCOSUD</Select.Option>
							<Select.Option value="FINANCIERA TFC">FINANCIERA TFC</Select.Option>
							<Select.Option value="FINAN. PROEMPRESA">FINAN. PROEMPRESA</Select.Option>
							<Select.Option value="FINANCIERA OH">FINANCIERA OH</Select.Option>
						</Select>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label="Moneda" name={["cuentaCTS", "tipoMoneda"]} rules={[{ required: !existeCuenta }]}>
						{/* <Input disabled={!existeCuenta} /> */}
						<Select disabled={existeCuenta}>
							<Select.Option value="SOLES">SOLES</Select.Option>
							<Select.Option value="DOLARES">DOLARES</Select.Option>
						</Select>
					</Form.Item>
				</Col>
				{/* <Col span={12}>
					<Form.Item label="Cuenta" name={["cuentaCTS", "nroCuenta"]} rules={[{ required: existeCuenta }]}>
						<Input disabled={!existeCuenta} />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="Cuenta CCI"
						name={["cuentaCTS", "nroCuentaCCI"]}
						rules={[{ required: existeCuenta }]}
					>
						<Input disabled={!existeCuenta} />
					</Form.Item>
				</Col> */}
			</Row>
		</>
	);
};

const ContactoEmergencia = () => {
	return (
		<>
			<Divider plain style={{ color: "#457b9d", borderColor: "#219ebc" }}>
				<Space>
					<PhoneOutlined /> Contacto de Emergencias
				</Space>
			</Divider>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item label="Nombres" name={["contactoEmergencia", "nombres"]}>
						<Input />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label="Telefono" name={["contactoEmergencia", "telefono"]}>
						<Input />
					</Form.Item>
				</Col>
			</Row>
		</>
	);
};

export default FormularioInformacionPersonal;
