import React, { useContext } from "react";
import { useAtom } from "jotai";
import { asistenciaAtom, asistenciaSeleccionadaAtom, formInstanceAtom, openModalRegistrarAsistenciaAtom } from "../../storage/MisDocumentosStorage";
import { AsistenciaService } from "services/v3";
import { Modal, notification } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons"
import { MisDocumentosContext } from "context/v3/MisDocumentosContext";
import { SecurityContext } from "context/SecurityContextProvider";

const { confirm } = Modal;

const useModalRegistrarAsistencia = () => {

    const { getDocumentos } = useContext(MisDocumentosContext);
    const { getUsuarioAutenticado } = useContext(SecurityContext);

    const [open, setOpen] = useAtom(openModalRegistrarAsistenciaAtom);
    const [asistenciaSeleccionada, setAsistenciaSeleccionada] = useAtom(asistenciaSeleccionadaAtom);
    const [asistencia, setAsistencia] = useAtom(asistenciaAtom);
    const [formInstance, setFormInstance] = useAtom(formInstanceAtom);

    const abrirModal = (asistencia) => {
        setAsistenciaSeleccionada(asistencia);
        setOpen(true);
    }

    const cerrarModal = () => {
        setAsistenciaSeleccionada(undefined);
        setAsistencia(undefined);
        setOpen(false);
    }

    const fetchAsistencia = async () => {
        const asistencia = await AsistenciaService.obtener(asistenciaSeleccionada.id);
        setAsistencia(asistencia);
    }

    const firmarAsistencia = () => {
        formInstance.validateFields()
            .then(async (formulario) => {
                confirm({
                    title: `Desea firmar su registro de asistencia?`,
                    icon: <ExclamationCircleFilled />,
                    okText: 'Sí, Firmar!',
                    cancelText: 'No',
                    centered: true,
                    onOk() {
                        return AsistenciaService.firmar(asistenciaSeleccionada.id, formulario)
                            .then(() => {
                                getDocumentos(getUsuarioAutenticado().empresaSeleccionada.id, getUsuarioAutenticado().numeroDocumentoIdentidad);

                                notification.success({
                                    message: "Registro de asistencia firmado!.",
                                    description: "Operación realizada exitosamente."
                                });

                                cerrarModal();
                            })
                            .catch((error) => {
                                console.error('Error al firmar el registro de asistencia:', error);
                                notification.error({
                                    title: 'Error',
                                    content: 'No se pudo firmar el registro de asistencia. Por favor, intente de nuevo.',
                                });
                            });
                    },
                });
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });



    }

    return {
        open,
        asistenciaSeleccionada,
        asistencia,
        setFormInstance,
        firmarAsistencia,
        fetchAsistencia,
        abrirModal,
        cerrarModal
    };
};

export default useModalRegistrarAsistencia;