import React, { useState } from "react";
import { List, Tag, Button, Space, Input, Avatar, Comment, Tooltip } from "antd";
import { HeartOutlined, LikeOutlined, CommentOutlined } from "@ant-design/icons";
import moment from "moment";
import "moment/locale/es";
import { PublicacionContenido } from "../PublicacionContenido";
import InfiniteScroll from "react-infinite-scroll-component";
import useComentario from "../../../../hooks/useComentario";

const PublicacionItem = ({ item, handleHeartClick, handleLikeClick }) => {
	const [showComments, setShowComments] = useState(false);
	const [newComment, setNewComment] = useState("");
	const { comentarios, totalComentarios, loadMoreComentarios, fetchComentarios, agregarComentario, yaComento } =
		useComentario(item.id);

	const toggleComments = async () => {
		setShowComments(!showComments);
		if (!showComments) fetchComentarios(0);
	};

	const handleCommentChange = (e) => setNewComment(e.target.value);

	const handleAddComment = async () => {
		if (!newComment.trim()) return; // No enviar comentarios vacíos

		await agregarComentario(newComment); // ⬅ Llama a la función para agregar comentario
		setNewComment(""); // ⬅ Limpia el input
	};

	return (
		<List.Item
			key={item.id}
			style={{
				marginBottom: "16px",
				borderRadius: "8px",
				boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
				overflow: "hidden",
				border: "1px solid #f0f0f0",
				background: "white",
				paddingBottom: "12px",
			}}
		>
			<PublicacionContenido item={item} />

			{/* Íconos de interacción */}
			<Space style={{ padding: "12px" }}>
				<Button
					type="text"
					icon={<HeartOutlined style={{ color: item.heartedColaborador ? "red" : undefined }} />}
					onClick={() => handleHeartClick(item.id)}
				>
					{item.hearts}
				</Button>
				<Button
					type="text"
					icon={<LikeOutlined style={{ color: item.likedColaborador ? "blue" : undefined }} />}
					onClick={() => handleLikeClick(item.id)}
				>
					{item.likes}
				</Button>
				<Button
					type="text"
					icon={<CommentOutlined style={{ color: item.comments ? "skyblue" : undefined }} />}
					onClick={toggleComments}
				>
					{item.comments || 0}
				</Button>
			</Space>

			{/* Sección de comentarios */}
			{showComments && (
				// <div style={{ padding: "12px", borderTop: "1px solid #f0f0f0" }}>
				<div
					id="scrollableDiv"
					style={{
						height: 200,
						overflow: "auto",
						padding: "0 16px",
						border: "1px solid rgba(140, 140, 140, 0.35)",
					}}
				>
					{/* Campo para agregar comentario (Solo si el usuario NO ha comentado) */}
					{!yaComento && (
						<div>
							<Input.TextArea
								rows={2}
								placeholder="Escribe un comentario..."
								value={newComment}
								onChange={handleCommentChange}
								style={{ marginTop: "8px" }}
							/>
							<Button type="primary" style={{ marginTop: "8px" }} onClick={() => handleAddComment()}>
								Comentar
							</Button>
						</div>
					)}
					<p></p>
					<h4>Comentarios</h4>
					<InfiniteScroll
						dataLength={comentarios.length}
						next={loadMoreComentarios}
						hasMore={comentarios.length < totalComentarios}
						loader={<p>Cargando más comentarios...</p>}
						endMessage={<p style={{ textAlign: "center" }}>No hay más comentarios</p>}
						scrollableTarget="scrollableDiv"
					>
						{comentarios.map((comment, index) => {
							const fechaRegistro = moment(comment.fechaRegistro);
							const tiempoRelativo = fechaRegistro.fromNow(); // Ej: "hace 9 horas"
							const fechaTooltip = fechaRegistro.format("DD MMM YYYY HH:mm"); // Ej: "27 Feb 2025 14:29"

							return (
								<Comment
									key={index}
									author={<a>{comment.nombreCompletoUsuarioRegistro}</a>}
									avatar={
										<Avatar style={{ backgroundColor: "#87d068", marginRight: 8 }}>
											{comment.nombreCompletoUsuarioRegistro.substring(0, 3).toUpperCase()}
										</Avatar>
									}
									content={<p>{comment.contenido}</p>}
									datetime={
										<Tooltip title={fechaTooltip}>
											<span>{tiempoRelativo}</span>
										</Tooltip>
									}
								/>
							);
						})}
					</InfiniteScroll>
				</div>
			)}
		</List.Item>
	);
};

export default PublicacionItem;
