import React, { useContext, useEffect } from "react";
import { SecurityContext } from "../../context/SecurityContextProvider";
import { Route, Redirect, useHistory } from "react-router-dom";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { VersionApiColaborador } from "enum/VersionApiColaborador";
import { OnboardingService } from "services";
import { useValidarUrl } from "hooks";

let subdominio = window.location.host.split(".")[0];

export const PrivateRoute = ({ component: Component, ...rest }) => {

	const {
		getVersionApiColaborador,
		estaAutenticado,
		getUsuarioAutenticado,
		setActualizarSesionUsuario,
		setEmpresaSeleccionada
	} = useContext(SecurityContext);

	let isOnboarding = false;
	let isDimarza = false;

	if (estaAutenticado) {

		if (subdominio === "app") {
			subdominio = "MOVIL";
		}

		PortalColaboradorService
			.obtenerSesionUsuarioActualizada({ subdominio: subdominio })
			.then((response) => {
				const empresaSeleccionadaId = getUsuarioAutenticado().empresaSeleccionada.id;
				setActualizarSesionUsuario(response.data);
				setEmpresaSeleccionada(empresaSeleccionadaId);
			})
			.catch((error) => {
				console.log("no se pudo actualizar la sesión del usuario", error);
			});

		console.log("privateRoute****")


		if (getUsuarioAutenticado()?.empresaSeleccionada.moduloOnboardingHabilitado) {
			const isDimarza = getUsuarioAutenticado()?.empresaSeleccionada?.ruc === "20536649205" ? true : false;
			
			if (isDimarza) {
				if (
					getUsuarioAutenticado().empresaSeleccionada.colaborador.estado === "ONBOARDING"
				) {
					if (
						!getUsuarioAutenticado().empresaSeleccionada.colaborador.informacionPersonalCompletado ||
						(!getUsuarioAutenticado().empresaSeleccionada.colaborador.formulariosDigitalesCompletado &&
							getUsuarioAutenticado().empresaSeleccionada.colaborador.estado === "ONBOARDING")
					) {
						isOnboarding = true;
					}
				}
			} else {
				if (
					getUsuarioAutenticado().empresaSeleccionada.colaborador.estado === "ONBOARDING" ||
					getUsuarioAutenticado().empresaSeleccionada.colaborador.estado === "ACTIVO"
				) {
					if (
						!getUsuarioAutenticado().empresaSeleccionada.colaborador.informacionPersonalCompletado ||
						(!getUsuarioAutenticado().empresaSeleccionada.colaborador.formulariosDigitalesCompletado &&
							getUsuarioAutenticado().empresaSeleccionada.colaborador.estado === "ONBOARDING")
					) {
						isOnboarding = true;
					}
				}
			}


		}
	}

	return (
		<Route
			{...rest}
			render={(props) =>
				estaAutenticado() === true ? (
					<Component {...props} isOnboarding={isOnboarding} />
				) : (
					<Redirect to="/login" />
				)
			}
		/>
	);
};
