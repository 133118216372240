import React from "react";
import { Container } from "components/Container";
import { VisorPdf } from "../VisorPdf";
import { ModalFirmar } from "../ModalFirmar";
import { ModalExito } from "../ModalExito";
import { ListaDocumentos } from "./componentes/ListaDocumentos";
import { VisorFormulario } from "./componentes";
import { ModalConsultar } from "../VisorPdf/Header/ModalConsultar";
import { useAtom } from "jotai";
import { ModalMensajeAlerta } from "../ModalMensajeAlerta";
import { ModalSubirArchivo } from "../ModalSubirArchivo/ModalSubirArchivo";
import {
	loadingPdfAtom,
} from "pages/v3/mis-pendientes/componentes/MisDocumentosWeb/MisDocumentosWebStorage";
import { ModalValidarDeclaracion } from "../ModalValidarDeclaracion";
import { ModalRegistrarAsistencia } from "../../common/components";

const MisDocumentosWeb = () => {
	const [loadingPdf] = useAtom(loadingPdfAtom);

	return (
		<React.Fragment>
			<Container titulo="Mis Pendientes">
				<ListaDocumentos />
				<VisorFormulario loadingPdf={loadingPdf} />
				<VisorPdf />
				<ModalFirmar />
				<ModalValidarDeclaracion />
				<ModalExito />
				<ModalSubirArchivo />
				<ModalConsultar />
				<ModalMensajeAlerta />
				<ModalRegistrarAsistencia />
			</Container>
		</React.Fragment>
	);
};

export default MisDocumentosWeb;
