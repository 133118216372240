export const CategoriaDocumento = {
	FORMULARIO: "869c9220-e9bd-4285-af35-656a8935e7e5",
	CONTRATOS: "69c9220-e9bd-4285-af35-656a8935e7e4",
	DOCUMENTO_LABORAL: "1e2cf094-d086-4626-a4bb-ea16170ca18d",
	CARGA_DOCUMENTO: "869c9220-e9bd-4285-af8e-656a8939c2d3",
	FORMATO_AUSENCIA: "976ec9a8-27cd-47e6-9a1c-9916e1dc5743",
	DOCUMENTO_PERSONAL: "c0779f7a-d8d8-433b-9347-08ac511a1fb9",
	CARGA_MANUAL: "566ec9a8-27cd-47e6-9a1c-9916e1dc5732",
	FLUJO_FIRMA: "a0260d32-ce5b-4269-91d4-cc390725b85b",
	REGISTRO_ASISTENCIA: "a0260d32-ce5b-4269-91d4-cc390725b85c",
	TAREA_PENDIENTE: "a0260d32-ce5b-4269-91d4-cc390725b85d"
};
